// src/admin_elements/calculations/Final/FinalCalculations.jsx

import React from 'react';
import PropTypes from 'prop-types';
import styles from './FinalCalculations.module.css';

const FinalCalculations = ({ selectedCalcType, onCalcTypeChange }) => {
  const handleCalcTypeClick = (type) => {
    onCalcTypeChange(type);
  };

  return (
    <div className={styles.container}>
      <h2>Final Calculations</h2>

      {/* Vertical button group */}
      <div className={styles.buttonGroup}>
        <button className={styles.calcButton} onClick={() => handleCalcTypeClick('AnnualEarningsPerYear')}>
          Annual Earnings Per Year
        </button>
        <button className={styles.calcButton} onClick={() => handleCalcTypeClick('AnnualEarningsPerWeek')}>
          Annual Earnings Per Week
        </button>
        <button className={styles.calcButton} onClick={() => handleCalcTypeClick('TotalLaborCostPerHour')}>
          Total Labor Cost Per Hour
        </button>
        <button className={styles.calcButton} onClick={() => handleCalcTypeClick('TotalLaborCostPerYear')}>
          Total Labor Cost Per Year
        </button>
        <button className={styles.calcButton} onClick={() => handleCalcTypeClick('TotalLaborCostPerProductiveHour')}>
          Total Labor Cost Per Productive Hour
        </button>
      </div>
    </div>
  );
};

FinalCalculations.propTypes = {
  selectedCalcType: PropTypes.string,
  onCalcTypeChange: PropTypes.func.isRequired,
};

FinalCalculations.defaultProps = {
  selectedCalcType: null,
};

export default FinalCalculations;
