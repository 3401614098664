// src/admin_elements/Calculations/Oncosts/OncostsGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const RatesGraph = () => {
  return <CalculationGraph calcType="Rates" />;
};

export default RatesGraph;
