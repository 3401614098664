// src/services/api/fetchWagesSummary.js
import { supabase } from '../supabaseClient';

/**
 * Fetch Wages top-level summary data from vw_wages_sum_v1
 *
 * @param {number|string} dataAgreementId
 * @param {string} quarterDate (YYYY-MM-DD)
 * @returns {{ data: Array, error: Error|null }}
 */
export const fetchWagesSummary = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    return {
      data: [],
      error: new Error('Data Agreement ID and Quarter Date are required.')
    };
  }

  try {
    const { data, error } = await supabase
      .from('vw_wages_sum_v1') // your Supabase view name
      .select('quarterdate, level1_class, level2_class, level3_class')
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('quarterdate', { ascending: true })
      .order('dataagreementid', { ascending: true });

    if (error) {
      throw error;
    }

    if (!data || data.length === 0) {
      return {
        data: [],
        error: new Error(
          'No wages summary found for the selected Data Agreement and Quarter Date.'
        )
      };
    }

    return { data, error: null };
  } catch (err) {
    console.error('Error fetching wages summary:', err.message);
    return { data: [], error: err };
  }
};
