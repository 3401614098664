// src/admin_elements/Calculations/Superannuation/SuperannuationGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const SuperannuationGraph = () => {
  return <CalculationGraph calcType="Superannuation" />;
};

export default SuperannuationGraph;
