// src/admin_elements/calculations/Wages/WagesCalculations.jsx
import React, { useState } from 'react';
import styles from './WagesCalculations.module.css';

// Sub-sections
import WagesTopLevel from './sections/WagesTopLevel';
import WagesMidLevel from './sections/WagesMidLevel';
import WagesGranular from './sections/WagesGranular';

const WagesCalculations = ({ dataAgreementId, quarterDate, tradepersonId }) => {
  // Accordions
  const [topOpen, setTopOpen] = useState(true);
  const [midOpen, setMidOpen] = useState(false);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>Wages Calculations</h2>

      {/* TOP-LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setTopOpen(!topOpen)}
        >
          <span>Top-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <WagesTopLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate}
            />
          </div>
        )}
      </div>

  {/* MID-LEVEL */}
<div className={styles.accordionItem}>
  <div
    className={styles.accordionHeader}
    onClick={() => setMidOpen(!midOpen)}
  >
    <span>Mid-Level Calculations</span>
    <span className={styles.toggleIcon}>{midOpen ? '-' : '+'}</span>
  </div>
  {midOpen && (
    <div className={styles.accordionContent}>
      <WagesMidLevel
        dataAgreementId={dataAgreementId}
        quarterDate={quarterDate}
      />
    </div>
  )}
</div>

      {/* GRANULAR */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setGranOpen(!granOpen)}
        >
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <WagesGranular
              dataAgreementId={dataAgreementId}
              tradepersonId={tradepersonId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WagesCalculations;
