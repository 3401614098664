// src/admin_elements/ExcelUploadModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './styles/ExcelUploadModal.module.css';
import * as XLSX from 'xlsx';
import { FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';
import worksheetConfigurations from './config/worksheetConfig';
import ParsedDataDisplay from './ParsedDataDisplay';
import { master_insert_excel } from "../../services/api/index.js";
import { excelSerialToDate, parseDateString, formatDate } from './helper/dateUtils'; // Import helper functions

Modal.setAppElement('#root'); // Ensure this matches your app's root element

const ExcelUploadModal = ({ isOpen, onClose }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [parsedData, setParsedData] = useState({});
  const [errors, setErrors] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  // Handle Escape key to close modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen && !isProcessing) {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, isProcessing]);

  // Handle overlay click to close modal
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget && !isProcessing) {
      handleClose();
    }
  };


  const handleValidate = async () => {
    if (Object.keys(parsedData).length === 0) {
      toast.error('No data to validate. Please parse an Excel file first.');
      return;
    }
  
    setIsProcessing(true);
    setProgress(10);
    setErrors([]); // Reset previous errors
  
    try {
      console.log('Parsed Data:', parsedData);
  
      // Send parsedData to the Supabase function
      const result = await master_insert_excel(parsedData);
      console.log('Validation Response:', result);
  
      // Adjusted condition to check 'status' instead of 'success'
      if (result.status === 'success') {
        toast.success('Data validated and inserted successfully!');
        setProgress(100);
      } else {
        // Handle errors
        if (result.errors && Array.isArray(result.errors)) {
          setErrors(result.errors);
          result.errors.forEach((error) => toast.error(error));
        } else {
          toast.error('Validation failed. Please check the errors.');
        }
        setProgress(0);
      }
    } catch (error) {
      console.error('Validation Error:', error);
      const errorMessage = error.message || 'Validation failed. Check the console for more details.';
      toast.error(errorMessage);
      setProgress(0);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrors([]);
    setProgress(0);
    setParsedData({});
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select an Excel file to upload.');
      return;
    }

    setIsProcessing(true);
    setProgress(10);
    setErrors([]);
    setParsedData({});

    try {
      // Read the file as ArrayBuffer
      const data = await file.arrayBuffer();
      setProgress(30);

      // Parse the Excel workbook with proper date handling
      const workbook = XLSX.read(data, {
        type: 'array',
        cellDates: true, // Parse dates as Date objects
      });
      setProgress(50);

      const configKeys = Object.keys(worksheetConfigurations);

      // Initialize result object
      const result = {};

      // Iterate through each worksheet configuration
      for (const key of configKeys) {
        const config = worksheetConfigurations[key];
        const { tableName } = config;

        // Check if the worksheet exists in the workbook
        if (!workbook.SheetNames.includes(tableName)) {
          if (tableName !== 'data') { // 'data' worksheet is handled separately
            setErrors(prevErrors => [...prevErrors, `Missing worksheet: ${tableName}`]);
            toast.error(`Missing worksheet: ${tableName}`);
            continue; // Skip to the next worksheet
          }
        }

        if (tableName === 'data') {
          // Handle the 'data' worksheet containing multiple tables
          if (!workbook.SheetNames.includes('data')) {
            setErrors(prevErrors => [...prevErrors, `Missing worksheet: data`]);
            toast.error(`Missing worksheet: data`);
            continue;
          }

          const worksheetData = workbook.Sheets['data'];
          const jsonData = XLSX.utils.sheet_to_json(worksheetData, { defval: null });
          setProgress(prev => prev + 10); // Increment progress

          const dataConfig = worksheetConfigurations['data'];

          // Validate required columns
          const dataErrors = validateColumns(jsonData, dataConfig);
          if (dataErrors.length > 0) {
            setErrors(prevErrors => [...prevErrors, ...dataErrors]);
            toast.error('Some columns are missing or data is invalid in the data worksheet.');
            continue;
          }

          // Segregate data based on table_name
          const segregatedData = segregateDataByTable(jsonData, dataConfig);
          setProgress(prev => prev + 10); // Increment progress

          // Merge segregated data into result
          Object.keys(segregatedData).forEach(table => {
            if (!result[table]) {
              result[table] = [];
            }
            result[table] = result[table].concat(segregatedData[table]);
          });

        } else {
          // Handle separate worksheets
          const worksheetData = workbook.Sheets[tableName];
          const jsonData = XLSX.utils.sheet_to_json(worksheetData, { defval: null });
          setProgress(prev => prev + 10); // Increment progress

          // Validate required columns
          const sheetErrors = validateColumns(jsonData, config);
          if (sheetErrors.length > 0) {
            setErrors(prevErrors => [...prevErrors, ...sheetErrors]);
            toast.error(`Some columns are missing or data is invalid in worksheet: ${tableName}`);
            continue;
          }

          // Segregate data with date conversion
          const segregatedData = segregateDataByTable(jsonData, config);
          setProgress(prev => prev + 10);

          // Add data to result
          result[tableName] = segregatedData[tableName];
        }
      }

      setProgress(90);

      setParsedData(result);
      console.log('Parsed Data:', result); // Verify in console
      setProgress(100);
      toast.success('Excel file processed successfully!');
    } catch (error) {
      console.error('Processing Error:', error);
      toast.error(`Processing failed: ${error.message}`);
      setProgress(0);
    } finally {
      setIsProcessing(false);
    }
  };

  // Function to validate required columns
  const validateColumns = (data, config) => {
    const sheetErrors = [];
    const requiredColumns = Object.keys(config.columns);

    if (data.length === 0) {
      sheetErrors.push(`Worksheet "${config.tableName}" is empty.`);
      return sheetErrors;
    }

    const firstRow = data[0];
    requiredColumns.forEach((col) => {
      if (!(col in firstRow)) {
        sheetErrors.push(`Worksheet "${config.tableName}" is missing column: "${col}"`);
      }
    });

    // Optional: Add more validations (e.g., data types) here

    return sheetErrors;
  };

  // Function to segregate data into different tables based on 'table_name'
  const segregateDataByTable = (data, config) => {
    const { identifierColumn, tables, dateFields } = config;
    const result = {};
    const sheetErrors = [];

    data.forEach((record, index) => {
      const tableName = config.tableName !== 'data' ? config.tableName : record[identifierColumn];
      if (!tableName) {
        sheetErrors.push(`Row ${index + 2}: Missing "${identifierColumn}" value.`);
        return;
      }

      const tableConfig = config.tableName === 'data' ? tables[tableName] : config;
      if (!tableConfig) {
        sheetErrors.push(`Row ${index + 2}: Unknown table name "${tableName}".`);
        return;
      }

      if (!result[tableName]) {
        result[tableName] = [];
      }

      // Extract and convert date fields
      const extractedRecord = {};
      Object.keys(tableConfig.columns).forEach((col) => {
        let cellValue = record[col];

        if (dateFields.includes(col)) {
          const dateFormat = tableConfig.columns[col].format || 'MM/dd/yyyy'; // Default format
          if (typeof cellValue === 'number') {
            // Convert Excel serial date to JavaScript Date
            const jsDate = excelSerialToDate(cellValue);
            if (jsDate) {
              const formattedDate = formatDate(jsDate);
              extractedRecord[col] = formattedDate;
            } else {
              sheetErrors.push(`Row ${index + 2}: Invalid serial date format in "${col}".`);
              extractedRecord[col] = null;
            }
          } else if (typeof cellValue === 'string') {
            // Attempt to parse the string date
            const formattedDate = parseDateString(cellValue, dateFormat);
            if (formattedDate) {
              extractedRecord[col] = formattedDate;
            } else {
              sheetErrors.push(`Row ${index + 2}: Unable to parse date string in "${col}".`);
              extractedRecord[col] = null;
            }
          } else if (cellValue instanceof Date) {
            // If it's already a Date object
            const formattedDate = formatDate(cellValue);
            if (formattedDate) {
              extractedRecord[col] = formattedDate;
            } else {
              sheetErrors.push(`Row ${index + 2}: Unable to format Date object in "${col}".`);
              extractedRecord[col] = null;
            }
          } else {
            sheetErrors.push(`Row ${index + 2}: Unsupported date format in "${col}".`);
            extractedRecord[col] = null;
          }
        } else {
          // Assign non-date fields
          extractedRecord[col] = cellValue;
        }
      });

      result[tableName].push(extractedRecord);
    });

    if (sheetErrors.length > 0) {
      setErrors(prevErrors => [...prevErrors, ...sheetErrors]);
      toast.error('Some errors occurred during parsing. Please check the errors section.');
    }

    return result;
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
      setFile(null);
      setErrors([]);
      setProgress(0);
      setParsedData({});
    }
  };

  // Define custom class names for react-modal
  const customStyles = {
    overlay: {
      base: styles.modalOverlay,
      afterOpen: styles.modalOverlayAfterOpen,
      beforeClose: styles.modalOverlayBeforeClose
    },
    content: {
      base: styles.modalContent,
      afterOpen: styles.modalContentAfterOpen,
      beforeClose: styles.modalContentBeforeClose
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Upload Excel File"
      className={customStyles.content}
      overlayClassName={customStyles.overlay}
      closeTimeoutMS={300} // Match the CSS transition duration
      shouldCloseOnOverlayClick={!isProcessing}
      onAfterOpen={() => setProgress(0)} // Reset progress on open
    >
      <h2>Upload Excel Workbook</h2>
      <div className={styles.instructions}>
        <p>Please fill out all the columns in the Excel worksheet and do not modify anything.</p>
        <p>Download the <a href="/template_workbook.xlsx" download="template_workbook.xlsx">template here</a>.</p>
      </div>
      <div className={styles.modalBody}>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          disabled={isProcessing}
          className={styles.fileInput}
        />
        {file && <p>Selected File: {file.name}</p>}

        {errors.length > 0 && (
          <div className={styles.error}>
            <p><strong>Errors:</strong></p>
            <ul>
              {errors.map((error, idx) => (
                <li key={idx}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        {isProcessing && (
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: `${progress}%` }}
            ></div>
            <span>{progress}%</span>
          </div>
        )}

        {/* Display Parsed Data */}
        {Object.keys(parsedData).length > 0 && (
          <div className={styles.parsedDataContainer}>
            <h3>Parsed Data:</h3>
            {Object.entries(parsedData)
              .filter(([tableName, data]) => tableName !== '__errors__') // Exclude errors
              .map(([tableName, data]) => (
                <ParsedDataDisplay key={tableName} sheetName={tableName} data={data} />
            ))}
          </div>
        )}
      </div>
      <div className={styles.modalActions}>
        <button
          onClick={handleUpload}
          className={styles.uploadButton}
          disabled={isProcessing || !file}
        >
          <FaUpload /> {isProcessing ? 'Processing...' : 'Parse Worksheets'}
        </button>

        <button
          onClick={handleValidate}
          className={styles.validateButton} 
          disabled={isProcessing || Object.keys(parsedData).length === 0}
        >
          Validate
        </button>

        <button
          onClick={handleClose}
          className={styles.cancelButton}
          disabled={isProcessing}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ExcelUploadModal;
