// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPage from './pages/reset_page';
import ChangePasswordPage from './pages/change_password';
import AdminPage from './pages/Admin'; // Import AdminPage
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import Verified from './pages/Verified'; // Correctly import Verified with uppercase 'V'
import NavBar from './components/NavBar';
import { AuthProvider } from './contexts/AuthContext';

// Import Toastify components and styles
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import React Query components
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Initialize React Query Client
const queryClient = new QueryClient();

function App() {
  return (
    // Wrap the entire app with QueryClientProvider
    <QueryClientProvider client={queryClient}>
      {/* AuthProvider can be inside QueryClientProvider */}
      <AuthProvider>
        <Router>
          <div>
            <NavBar />
            
            {/* Add ToastContainer here */}
            <ToastContainer
              position="top-right" // Position of the toast
              autoClose={5000} // Auto close after 5 seconds
              hideProgressBar={false} // Show progress bar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light" // Optional: Choose a theme ('light', 'dark', 'colored')
              style={{ width: '600px' }}
            />
                      
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/reset_page" element={<ResetPage />} />
              <Route path="/change_password" element={<ChangePasswordPage />} />
              <Route path="/verified" element={<Verified />} /> {/* Add Verified Route */}
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
