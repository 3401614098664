// src/admin_elements/calculations/Superable/SuperableCalculations.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import styles from './SuperableCalculations.module.css'; // Ensure CSS is correctly referenced

// Sub-sections
import SuperableTopLevel from './sections/SuperableTopLevel';
import SuperableMidLevel from './sections/SuperableMidLevel';
import SuperableGranular from './sections/SuperableGranular';

const SuperableCalculations = ({ dataAgreementId, tradepersonId, quarterDate }) => {
  // Accordion toggles
  const [topOpen, setTopOpen] = useState(true);
  const [midOpen, setMidOpen] = useState(false);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>Superable Calculations</h2>

      {/* TOP-LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setTopOpen((prev) => !prev)}
        >
          <span>Top-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <SuperableTopLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate} // Pass quarterDate prop
            />
          </div>
        )}
      </div>

      {/* MID-LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setMidOpen((prev) => !prev)}
        >
          <span>Mid-Level Details</span>
          <span className={styles.toggleIcon}>{midOpen ? '-' : '+'}</span>
        </div>
        {midOpen && (
          <div className={styles.accordionContent}>
            <SuperableMidLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate} // Pass quarterDate prop
            />
          </div>
        )}
      </div>

      {/* GRANULAR */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setGranOpen((prev) => !prev)}
        >
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <SuperableGranular
              dataAgreementId={dataAgreementId}
              tradepersonId={tradepersonId} // Pass tradepersonId prop
            />
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes for type checking and ensuring required props are provided
SuperableCalculations.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quarterDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SuperableCalculations;
