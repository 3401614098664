// src/services/api/superannuation/fetchSuperannuationTopLevel.js

import { supabase } from '../supabaseClient';

/**
 * Fetch high-level superannuation details, filtered by dataAgreementId and quarterDate.
 *
 * @param {number|string} dataAgreementId - The Data Agreement ID to filter by.
 * @param {string} quarterDate - The Quarter Date to filter by (format YYYY-MM-DD).
 * @returns {Promise<Array>} - An array of high-level superannuation details.
 * @throws {Error} - Throws error if fetching data fails.
 */
export const fetchSuperannuationTopLevel = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    throw new Error('dataAgreementId and quarterDate are required.');
  }

  try {
    const { data, error } = await supabase
      .from('vw_superannuation_v1') // Ensure the view name is correct
      .select('quarterdate, superannuation_item, no_of_units_per_annum, level1_class, level2_class, level3_class')
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('superannuation_item', { ascending: true }); // Adjust ordering as needed

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Superannuation high-level data:', error.message);
    throw error;
  }
};
