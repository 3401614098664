// src/services/api/fetchRatesGranularTables.js
import { supabase } from '../supabaseClient';

/**
 * Fetches table names that belong (in part) to the "Rates" classification
 * from vw_returntablenames using the simplified classification_text column.
 *
 * @returns {Promise<Array>} An array of objects containing table names.
 */
export const fetchRatesGranularTables = async () => {
  try {
    const { data, error } = await supabase
      .from('vw_returntablenames')
      .select('table_name')
      .ilike('classification', '%Rates%'); // Case-insensitive search for 'Rates'

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Rates granular table names:', error.message);
    throw error;
  }
};
