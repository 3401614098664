// src/services/api/fetchFactDataAgreement.js

import { supabase } from '../supabaseClient';

/**
 * Fetches fact data agreements from the database.
 *
 * @returns {Promise<Array>} - An array of data agreement objects.
 * @throws {Error} - Throws an error if the fetch fails.
 */
export const fetchDataAgreementGraph = async () => {
  try {
    const { data, error } = await supabase
      .from('fact_dataagreement') // Replace with your actual table name
      .select('dataagreementid, "Short Title"'); // Ensure you select the short title field

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching fact data agreements:', error.message);
    throw error;
  }
};
