// src/services/api/fetchWagesGranularTables.js
import { supabase } from '../supabaseClient';

/**
 * Fetches table names that belong (in part) to the "Wages" classification
 * from vw_returntablenames using a simplified classification column.
 *
 * @returns {Promise<Array>} An array of objects containing table names.
 */
export const fetchWagesGranularTables = async () => {
  try {
    const { data, error } = await supabase
      .from('vw_returntablenames')
      .select('table_name')
      // Case-insensitive search for 'Wages' in the classification
      .ilike('classification', '%Wages%'); 

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Wages granular table names:', error.message);
    throw error;
  }
};
