// src/admin_elements/quickNav/QuickNavigationPanel.jsx

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaUpload, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import ExcelUploadModal from '../excelModal/ExcelUploadModal';
import { fetchTradepersonsByDataAgreementId } from '../../services/api';
import { getStartDateOfQuarter } from '../../services/api/helper/dateUtils';
import styles from './QuickNavigationPanel.module.css';

const QuickNavigationPanel = ({
  dataAgreements,
  onSelectionChange,
  onTradepersonSelect,
  refreshDataAgreements,
  selectedTradepersonId,
}) => {
  // Data Agreement states
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedDataAgreementId, setSelectedDataAgreementId] = useState(null);

  // Year & Quarter
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState('Q1');

  // Tradepersons
  const [tradepersons, setTradepersons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Excel Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // =====================
  // 1. FETCH TRADEPERSONS
  // =====================
  const fetchTradepersons = useCallback(async (dataAgreementId) => {
    setLoading(true);
    setError(null);
    try {
      const result = await fetchTradepersonsByDataAgreementId(dataAgreementId);
      setTradepersons(result || []);
    } catch (err) {
      console.error('Error fetching tradepersons:', err);
      setError('Failed to fetch tradepersons. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  // =======================
  // 2. NOTIFY PARENT: QUARTER
  // =======================
  const notifyParent = useCallback(
    (dataAgreementId, year, quarter) => {
      const quarterStartDate = getStartDateOfQuarter(year, quarter);
      onSelectionChange(dataAgreementId, quarterStartDate);
    },
    [onSelectionChange]
  );

  // ===========================
  // EFFECT A: INITIAL LOAD + 
  // ONLY WHEN DATAAGREEMENTID CHANGES
  // ===========================
  useEffect(() => {
    if (dataAgreements.length > 0) {
      const initialId = dataAgreements[currentIndex].dataagreementid;
      setSelectedDataAgreementId(initialId);
      // Fetch tradepersons only for that data agreement
      fetchTradepersons(initialId);
      notifyParent(initialId, selectedYear, selectedQuarter);
    }
  }, [
    dataAgreements,
    currentIndex,
    fetchTradepersons,
    notifyParent,
    selectedYear,
    selectedQuarter,
  ]);

  useEffect(() => {
    if (selectedDataAgreementId) {
      // ONLY fetch tradepersons if data agreement changes
      fetchTradepersons(selectedDataAgreementId);
      // STILL notify parent about new data agreement + year/quarter
      notifyParent(selectedDataAgreementId, selectedYear, selectedQuarter);
    }
    // IMPORTANT: Do NOT include selectedTradepersonId here
  }, [
    selectedDataAgreementId,
    fetchTradepersons,
    notifyParent,
    selectedYear,
    selectedQuarter,
  ]);

  // ====================
  // 3. HANDLERS
  // ====================
  // Data Agreement nav
  const handleLeft = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      const newId = dataAgreements[newIndex].dataagreementid;
      setSelectedDataAgreementId(newId);
    }
  };

  const handleRight = () => {
    if (currentIndex < dataAgreements.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      const newId = dataAgreements[newIndex].dataagreementid;
      setSelectedDataAgreementId(newId);
    }
  };

  const handleDataAgreementChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const idx = dataAgreements.findIndex(
      (da) => da.dataagreementid === selectedId
    );
    if (idx !== -1) {
      setCurrentIndex(idx);
      setSelectedDataAgreementId(selectedId);
    }
  };

  // Excel upload
  const handleUploadSuccess = () => {
    setIsModalOpen(false);
    if (refreshDataAgreements) {
      refreshDataAgreements().then(() => {
        if (dataAgreements.length > 0) {
          setCurrentIndex(0);
          const newId = dataAgreements[0].dataagreementid;
          setSelectedDataAgreementId(newId);
        }
      });
    }
  };

  // Year & Quarter
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2020;
    const endYear = currentYear + 1;
    const years = [];
    for (let y = startYear; y <= endYear; y++) {
      years.push(y);
    }
    return years;
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  const handleQuarterClick = (q) => {
    setSelectedQuarter(q);
  };

  // ==============================
  // TRADEPERSON TILE CLICK HANDLER
  // ==============================
  const handleTradepersonClick = (tpId) => {
    // Just calls onTradepersonSelect to notify parent
    // No new fetch calls for tradepersons here -> no "Loading tradepersons"
    onTradepersonSelect(tpId);
  };

  // If no data agreements
  if (dataAgreements.length === 0) {
    return <p className={styles.noTradepersons}>No Data Agreements Available</p>;
  }

  // ============================
  // RENDER
  // ============================
  return (
    <div className={styles.quickNavContainer}>
      {/* === FIRST ROW: HEADERS === */}
      <div className={styles.headerRow}>
        <div className={styles.headerSection}>
          <h4 className={styles.sectionHeader}>Quarter / Year</h4>
        </div>
        <div className={styles.headerSection}>
          <h4 className={styles.sectionHeader}>Choose A Data Agreement</h4>
        </div>
        <div className={styles.headerSection}>
          <h4 className={styles.sectionHeader}>Actions</h4>
        </div>
      </div>

      {/* === SECOND ROW: Inputs and Actions === */}
      <div className={styles.inputRow}>
        {/* LEFT: Quarter / Year */}
        <div className={styles.inputSection}>
          <div className={styles.yearQuarterContainer}>
            <label htmlFor="yearSelect" className={styles.label}>
              Year:
            </label>
            <select
              id="yearSelect"
              value={selectedYear}
              onChange={handleYearChange}
              className={styles.select}
            >
              {getYearOptions().map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <div className={styles.quarterSelector}>
              {['Q1', 'Q2', 'Q3', 'Q4'].map((q) => (
                <button
                  key={q}
                  type="button"
                  className={
                    q === selectedQuarter
                      ? `${styles.quarterButton} ${styles.activeQuarter}`
                      : styles.quarterButton
                  }
                  onClick={() => handleQuarterClick(q)}
                >
                  {q}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* CENTER: Data Agreement */}
        <div className={styles.inputSection}>
          <div className={styles.dataAgreementNav}>
            <button
              onClick={handleLeft}
              disabled={currentIndex === 0}
              className={styles.navButton}
              aria-label="Previous Data Agreement"
            >
              <FaArrowLeft />
            </button>
            <select
              value={dataAgreements[currentIndex].dataagreementid}
              onChange={handleDataAgreementChange}
              className={styles.select}
            >
              {dataAgreements.map((item) => (
                <option key={item.dataagreementid} value={item.dataagreementid}>
                  {item['Short Title']}
                </option>
              ))}
            </select>
            <button
              onClick={handleRight}
              disabled={currentIndex === dataAgreements.length - 1}
              className={styles.navButton}
              aria-label="Next Data Agreement"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>

        {/* RIGHT: Actions */}
        <div className={styles.inputSection}>
          <button
            onClick={() => setIsModalOpen(true)}
            className={styles.uploadButton}
            title="Upload Excel Workbook"
            aria-label="Upload Excel Workbook"
          >
            <FaUpload /> Upload Excel
          </button>
        </div>
      </div>

      {/* === TRADEPERSON TILES === */}
      <div className={styles.tradepersonContainer}>
        {loading && <p>Loading tradepersons...</p>}
        {error && <p className={styles.error}>{error}</p>}

        {!loading && !error && tradepersons.length > 0 ? (
          tradepersons.map((person) => (
            <div
              key={person.tradepersonid}
              className={`${styles.tradepersonTile} ${
                person.tradepersonid === selectedTradepersonId ? styles.selected : ''
              }`}
              onClick={() => handleTradepersonClick(person.tradepersonid)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleTradepersonClick(person.tradepersonid);
              }}
              role="button"
              tabIndex={0}
              aria-pressed={person.tradepersonid === selectedTradepersonId}
            >
              <p>{person.Class || `Class ${person.tradepersonid}`}</p>
            </div>
          ))
        ) : (
          !loading &&
          !error && <p className={styles.noTradepersons}>No classes associated.</p>
        )}
      </div>

      {/* Excel Upload Modal */}
      <ExcelUploadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUploadSuccess={handleUploadSuccess}
      />
    </div>
  );
};

QuickNavigationPanel.propTypes = {
  dataAgreements: PropTypes.arrayOf(
    PropTypes.shape({
      dataagreementid: PropTypes.number.isRequired,
      'Short Title': PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onTradepersonSelect: PropTypes.func.isRequired,
  refreshDataAgreements: PropTypes.func.isRequired,
  selectedTradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

QuickNavigationPanel.defaultProps = {
  selectedTradepersonId: null,
};

export default QuickNavigationPanel;
