// src/components/NavBar.jsx
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../services/supabaseClient';
import { AuthContext } from '../contexts/AuthContext';

const NavBar = () => {
  const { user, setUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkRole = async () => {
      if (user) {
        console.log('Fetching role for user ID:', user.id); // Debugging log

        const { data, error } = await supabase
          .from('profile') // Ensure this matches your profile table
          .select('role')
          .eq('id', user.id)
          .single(); // Ensures only one row is returned

        if (error) {
          console.error('Error fetching user role:', error.message);
          setError('Failed to fetch user role.');
          setIsAdmin(false);
        } else {
          console.log('User role fetched:', data.role); // Debugging log
          setIsAdmin(data?.role === 'admin');
          setError(null);
        }
      } else {
        setIsAdmin(false);
      }
    };

    checkRole();
  }, [user]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setUser(null);
      navigate('/login');
    }
  };

  return (
    <nav className="bg-blue-600 text-white px-8 py-4 flex justify-between items-center">
      <div className="text-2xl font-bold">
        <Link to="/" className="hover:underline">CMC</Link>
      </div>
      <ul className="flex space-x-6">
        <li>
          <Link to="/" className="hover:underline">Home</Link>
        </li>
        <li>
          <Link to="/about" className="hover:underline">About</Link>
        </li>
        <li>
          <Link to="/contact" className="hover:underline">Contact</Link>
        </li>
        <li>
          <Link to="/pricing" className="hover:underline">Pricing</Link>
        </li>
        {user && (
          <>
            <li>
              <Link to="/dashboard" className="hover:underline">Dashboard</Link>
            </li>
            {isAdmin && (
              <li>
                <Link to="/admin" className="hover:underline">Admin</Link>
              </li>
            )}
          </>
        )}
        {user ? (
          <li>
            <button
              onClick={handleLogout}
              className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded-lg focus:outline-none"
            >
              Logout
            </button>
          </li>
        ) : (
          <li>
            <Link
              to="/login"
              className="bg-blue-800 hover:bg-blue-900 px-4 py-2 rounded-lg focus:outline-none"
            >
              Login
            </Link>
          </li>
        )}
      </ul>
      {error && <p className="text-red-500 text-sm ml-4">{error}</p>} {/* Display Errors */}
    </nav>
  );
};

export default NavBar;
