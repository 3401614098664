// src/services/api/fetchWagesMidLevel.js
import { supabase } from '../supabaseClient';

/**
 * Fetch mid-level wages data from vw_wages_v1
 *
 * @param {number|string} dataAgreementId
 * @param {string} quarterDate (format YYYY-MM-DD)
 * @returns {{ data: Array, error: Error|null }}
 */
export const fetchWagesMidLevel = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    return {
      data: [],
      error: new Error('dataAgreementId and quarterDate are required.')
    };
  }

  try {
    const { data, error } = await supabase
      .from('vw_wages_v1')
      .select(
        `
          quarterdate,
          wages_item,
          no_of_units_per_annum,
          factor,
          level1_class,
          level2_class,
          level3_class
        `
      )
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('quarterdate', { ascending: true })
      .order('dataagreementid', { ascending: true })
      .order('wages_order', { ascending: true });

    if (error) {
      throw error;
    }

    if (!data || data.length === 0) {
      return {
        data: [],
        error: new Error('No mid-level wages data found for the selected criteria.')
      };
    }

    return { data, error: null };
  } catch (err) {
    console.error('Error fetching mid-level wages:', err.message);
    return { data: [], error: err };
  }
};
