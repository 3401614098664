// src/services/api/fetchRatesV1.js

import { supabase } from '../supabaseClient';

/**
 * Fetch mid-level rates data from the Supabase view vw_rates_v1
 *
 * @param {number|string} dataAgreementId - The Data Agreement ID selected from QuickNavigationPanel
 * @param {string} quarterDate - The Quarter Date selected from QuickNavigationPanel (format: YYYY-MM-DD)
 * @returns {Promise<{ data: Array, error: Error|null }>}
 *
 * Example fields retrieved:
 *  quarterdate,
 *  rates_base_all_purpose AS rates_name,
 *  typename,
 *  amount,
 *  level1_class,
 *  level2_class,
 *  level3_class
 */
export const fetchRatesMidLevel  = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    return {
      data: [],
      error: new Error('Data Agreement ID and Quarter Date are required.'),
    };
  }

  try {
    // You can alias "rates_base_all_purpose" as "rates_name" in Supabase
    const { data, error } = await supabase
      .from('vw_rates_v1') // Your view name
      .select(`
        quarterdate,
        rates_base_all_purpose,
        typename,
        amount,
        level1_class,
        level2_class,
        level3_class
      `)
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      // Apply sorting:
      .order('quarterdate', { ascending: true })
      .order('dataagreementid', { ascending: true })
      .order('rates_order', { ascending: true });

    if (error) {
      throw error;
    }

    if (!data || data.length === 0) {
      return {
        data: [],
        error: new Error('No mid-level rates found for the selected Data Agreement and Quarter Date.'),
      };
    }

    return { data, error: null };
  } catch (error) {
    console.error('Error fetching mid-level rates:', error.message);
    return { data: [], error };
  }
};
