// src/services/api/fetchAccrualsMidLevel.js

import { supabase } from '../supabaseClient';

/**
 * Fetch mid-level accruals details, filtered by dataAgreementId and quarterDate.
 *
 * @param {number|string} dataAgreementId - The Data Agreement ID to filter by.
 * @param {string} quarterDate - The Quarter Date to filter by (format YYYY-MM-DD).
 * @returns {Promise<Array>} - An array of mid-level accrual details.
 * @throws {Error} - Throws error if fetching data fails.
 */
export const fetchAccrualsMidLevel = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    throw new Error('dataAgreementId and quarterDate are required.');
  }

  try {
    const { data, error } = await supabase
      .from('vw_accruals_v1') // Your mid-level accruals view
      .select(`
        quarterdate,
        accruals_item,
        no_of_units_per_annum,
        level1_class,
        level2_class,
        level3_class
      `)
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('accruals_order', { ascending: true });

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Accruals mid-level data:', error.message);
    throw error;
  }
};
