// src/services/api/master_insert_excel.js

import { supabase } from '../supabaseClient';

/**
 * Inserts parsed Excel data into Supabase by calling the master_insert_excel Postgres function.
 * @param {Object} parsedData - The JSON object containing data for each table.
 * @returns {Object} - The response from Supabase after insertion.
 */
export const master_insert_excel = async (parsedData) => {
  try {
    const { data, error } = await supabase
      .rpc('master_insert_excel', { input_json: parsedData });

    if (error) {
      throw error;
    }

    return data;
  } catch (err) {
    console.error('Supabase RPC Error:', err);
    throw err;
  }
};
