// src/pages/Admin.jsx

import React, { useState, useEffect } from 'react';
import { fetchFactDataAgreement } from '../services/api';

// Existing Imports
import SideNav from '../admin_elements/sideNav/SideNav';
import FactDataAgreementPanel from '../admin_elements/FactDataAgreementPanel';
import TradepersonList from '../admin_elements/tradepersonList';
import QuickNavigationPanel from '../admin_elements/quickNav/QuickNavigationPanel';

// Rates
import RatesCalculations from '../admin_elements/calculations/Rates/RatesCalculations';
import RatesGraph from '../admin_elements/calculations/Rates/RatesGraph';

// Wages
import WagesCalculations from '../admin_elements/calculations/Wages/WagesCalculations';
import WagesGraph from '../admin_elements/calculations/Wages/WagesGraph';

// Allowances
import AllowancesCalculations from '../admin_elements/calculations/Allowances/AllowancesCalculations';
import AllowancesGraph from '../admin_elements/calculations/Allowances/AllowancesGraph';

// Accrual
import AccrualCalculations from '../admin_elements/calculations/Accrual/AccrualCalculations';
import AccrualGraph from '../admin_elements/calculations/Accrual/AccrualGraph';

// Superable
import SuperableCalculations from '../admin_elements/calculations/Superable/SuperableCalculations';
import SuperableGraph from '../admin_elements/calculations/Superable/SuperableGraph';

// Superannuation
import SuperannuationCalculations from '../admin_elements/calculations/Superannuation/SuperannuationCalculations';
import SuperannuationGraph from '../admin_elements/calculations/Superannuation/SuperannuationGraph';

// Oncosts
import OnCostsCalculations from '../admin_elements/calculations/OnCosts/OnCostsCalculations';
import OnCostsGraph from '../admin_elements/calculations/OnCosts/OncostsGraph';

// Final
import FinalCalculations from '../admin_elements/calculations/Final/FinalCalculations';
import FinalGraph from '../admin_elements/calculations/Final/FinalGraph';

import LandingPage from './LandingPage'; // Import the simplified LandingPage component
import styles from '../styles/Admin.module.css';

const Admin = () => {
  // State for FactDataAgreements
  const [factDataRows, setFactDataRows] = useState([]);
  const [selectedDataAgreementId, setSelectedDataAgreementId] = useState(null);
  const [selectedTradepersonId, setSelectedTradepersonId] = useState(null);
  const [quarterStartDate, setQuarterStartDate] = useState(null);

  // Loading & error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Tracks which nav section is selected (e.g., 'calcRates', 'calcFinal', etc.)
  const [selectedSection, setSelectedSection] = useState(null);

  // NEW: Which "Final" calc type is chosen
  const [finalCalcType, setFinalCalcType] = useState(null);

  // Whenever selectedSection changes from null to something else, load data
  useEffect(() => {
    if (selectedSection !== null) {
      loadInitialData();
    }
  }, [selectedSection]);

  // Fetch data agreements
  const loadInitialData = async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedData = await fetchFactDataAgreement();
      setFactDataRows(fetchedData || []);
      if (fetchedData && fetchedData.length > 0) {
        setSelectedDataAgreementId(fetchedData[0].dataagreementid);
      }
    } catch (err) {
      console.error('Error loading data:', err.message);
      setError('Failed to load data agreements.');
    } finally {
      setLoading(false);
    }
  };

  // Handlers
  const handleDataAgreementSelect = (dataAgreementId) => {
    setSelectedDataAgreementId(dataAgreementId);
  };

  const handleTradepersonSelect = (tradepersonId) => {
    console.log('Tradeperson ID in Admin:', tradepersonId);
    setSelectedTradepersonId(tradepersonId);
  };

  const handleSelectionChange = (dataAgreementId, quarterDate) => {
    setSelectedDataAgreementId(dataAgreementId);
    setQuarterStartDate(quarterDate);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        {/* Side Navigation */}
        <div className={styles.leftPanel}>
          <SideNav selectedSection={selectedSection} onSelect={setSelectedSection} />
        </div>

        {/* Content Area */}
        <div className={styles.contentArea}>
          <div className={styles.middlePanel}>
            {/* If no section is selected, show LandingPage */}
            {selectedSection === null ? (
              <LandingPage />
            ) : (
              <>
                {/* Quick Navigation Panel */}
                <QuickNavigationPanel
                  dataAgreements={factDataRows}
                  onSelect={handleDataAgreementSelect}
                  onTradepersonSelect={handleTradepersonSelect}
                  refreshDataAgreements={loadInitialData}
                  onSelectionChange={handleSelectionChange}
                  selectedTradepersonId={selectedTradepersonId}
                />

                {loading && <p className={styles.loading}>Loading...</p>}
                {error && <p className={styles.error}>{error}</p>}

                {/* ======================== DATA AGREEMENT ====================== */}
                {selectedSection === 'dataAgreement' && (
                  <FactDataAgreementPanel
                    dataAgreements={factDataRows}
                    selectedDataAgreementId={selectedDataAgreementId}
                  />
                )}

                {/* ======================== TRADEPERSON ========================= */}
                {selectedSection === 'tradeperson' && (
                  <TradepersonList
                    dataAgreementId={selectedDataAgreementId}
                    onTradepersonSelect={handleTradepersonSelect}
                  />
                )}

                {/* ======================== RATES ============================== */}
                {selectedSection === 'calcRates' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.ratesSection}>
                      <RatesCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <RatesGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== WAGES ============================== */}
                {selectedSection === 'calcWages' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.wagesSection}>
                      <WagesCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <WagesGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== ALLOWANCES ========================= */}
                {selectedSection === 'calcAllowances' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.allowancesSection}>
                      <AllowancesCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <AllowancesGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== ACCRUALS =========================== */}
                {selectedSection === 'calcAccrual' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.accrualSection}>
                      <AccrualCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <AccrualGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== SUPERABLE ========================== */}
                {selectedSection === 'calcSuperable' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.superableSection}>
                      <SuperableCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <SuperableGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== SUPERANNUATION ===================== */}
                {selectedSection === 'calcSuperannuation' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.superannuationSection}>
                      <SuperannuationCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <SuperannuationGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== ONCOSTS ============================ */}
                {selectedSection === 'calcOncosts' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.oncostsSection}>
                      <OncostsCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <OncostsGraph
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}

                {/* ======================== FINAL CALCULATIONS ============================== */}
                {selectedSection === 'calcFinal' && (
                  <div className={styles.calcContainer}>
                    <div className={styles.finalSection}>
                      <FinalCalculations
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}

                        selectedCalcType={finalCalcType}
                        onCalcTypeChange={setFinalCalcType}
                      />
                    </div>
                    <div className={styles.graphSection}>
                      <FinalGraph
                        calcType={finalCalcType}
                        dataAgreementId={selectedDataAgreementId}
                        quarterDate={quarterStartDate}
                        tradepersonId={selectedTradepersonId}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
