// src/pages/LandingPage.jsx

import React from 'react';
import Lottie from 'lottie-react';
import { FaArrowLeft } from 'react-icons/fa'; // Importing the left arrow icon from React Icons
import animationData from '../assets/animation.json'; // Ensure this path is correct
import styles from '../styles/LandingPage.module.css';

const LandingPage = () => {
  return (
    <div className={styles.landingContainer}>
      <div className={styles.animation}>
        <Lottie animationData={animationData} loop={true} />
      </div>
      <div className={styles.description}>
        <h2>Welcome to the Admin Dashboard</h2>
        <p>
          Manage your data agreements efficiently. Use the navigation on the left to access different sections and perform various calculations.
          Easily handle your data and gain valuable insights with our comprehensive tools.
        </p>
        {/* Optional Instructional Text */}
        <p className={styles.instruction}>
          <FaArrowLeft className={styles.arrowIcon} /> Click on the calculations on the left to get started.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
