// src/services/api/fetchGranularData.js

import { supabase } from '../supabaseClient';
import { TABLE_COLUMNS } from '../tableColumns';

/**
 * Fetch data from a given table, filtered by dataAgreementId and optionally tradepersonId.
 *
 * @param {string} tableName - The name of the table to fetch data from.
 * @param {number|string} dataAgreementId - The Data Agreement ID to filter by.
 * @param {number|string} [tradepersonId] - Optional Tradeperson ID to filter by.
 * @returns {Promise<Array>} - An array of rows from the specified table.
 * @throws {Error} - Throws error if fetching data fails.
 */
export const fetchGranularData = async (tableName, dataAgreementId, tradepersonId) => {
  if (!tableName || !dataAgreementId) {
    throw new Error('tableName and dataAgreementId are required.');
  }

  try {
    const tableConfig = TABLE_COLUMNS[tableName];
    if (!tableConfig) {
      throw new Error(`No column configuration found for table ${tableName}.`);
    }

    const { primaryKey, columns } = tableConfig;

    // Ensure the primary key is included in the selected columns
    const selectedColumns = columns.includes(primaryKey)
      ? columns
      : [primaryKey, ...columns];

    const columnsToFetch = selectedColumns.join(', ');

    let query = supabase
      .from(tableName)
      .select(columnsToFetch)
      .eq('dataagreementid', dataAgreementId);

    // Conditionally apply the tradepersonId filter
    const TABLES_WITH_TRADEPERSONID = [
      'dim_datadailyfares',
      'dim_datacarptmanallowance',
      'dim_dataincomeprotectionperweek',
      'dim_rates',
      'dim_toolallowance',
      'dim_datasideprodallowance',
      'dim_tradeperson',
      'dim_dataleadhand10',
      'dim_dataretentionpayment',
      'dim_dataotimemealallowance',
      'dim_dataredundancycontribution',
      'dim_firstaidlevel3perday',
      // Add other tables as necessary
    ];

    if (tradepersonId && TABLES_WITH_TRADEPERSONID.includes(tableName)) {
      query = query.eq('tradepersonid', tradepersonId);
    }

    // Order by 'start_date' for specific tables
    const TABLES_TO_ORDER_BY_START_DATE = ['dim_superannuation', 'dim_superminpayment'];

    if (TABLES_TO_ORDER_BY_START_DATE.includes(tableName)) {
      query = query.order('start_date', { ascending: true });
    }

    const { data, error } = await query;

    if (error) {
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error(`Error fetching data from ${tableName}:`, error.message);
    throw error;
  }
};
