// src/services/api/fetchMasterCalcYear.js

import { supabase } from '../supabaseClient';

/**
 * Fetches calculation data based on the provided parameters.
 *
 * @param {Object} params - The parameters for the calculation.
 * @param {number[]} params.dataAgreementIds - Array of data agreement IDs.
 * @param {number} params.year - The selected year.
 * @param {string} params.calcType - Type of calculation (e.g., 'Rates', 'Wages').
 * @param {string} params.limitOption - 'ALL', 'TOP5', or 'BOTTOM5'.
 * @returns {Promise<Object>} - The fetched data and any error.
 */
export const fetchMasterCalcYear = async ({
  dataAgreementIds = [],
  year,
  calcType = 'Rates', // Default to 'Rates' but can be overridden
  limitOption = 'ALL', // Default to 'ALL' but can be overridden
}) => {
  try {
    // Ensure dataAgreementIds is either an array or null
    const formattedDataAgreementIds =
      Array.isArray(dataAgreementIds) && dataAgreementIds.length > 0
        ? dataAgreementIds
        : null;

    console.log('Calling master_calc_year with parameters:', {
      p_dataagreementids: formattedDataAgreementIds,
      p_year: year,
      p_calculation_type: calcType,
      p_limit_option: limitOption,
    });

    const { data, error } = await supabase.rpc('master_calc_year', {
      p_dataagreementids: formattedDataAgreementIds,
      p_year: year,
      p_calculation_type: calcType,
      p_limit_option: limitOption,
    });

    if (error) {
      console.error('Supabase RPC Error:', error);
    }

    return { data, error };
  } catch (err) {
    console.error('Unexpected error in fetchMasterCalcYear:', err);
    throw err; // Re-throw the error to be caught in the frontend
  }
};
