// src/admin_elements/calculations/Allowances/sections/AllowancesGranular.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditableTable from '../../../../components/EditableTable';
import { fetchAllowancesGranularTables } from '../../../../services/api/fetchAllowancesGranularTables';
import { fetchGranularData } from '../../../../services/api/fetchGranularData';
import { updateTable } from '../../../../services/api/update_table';
import { TABLE_COLUMNS } from '../../../../services/tableColumns';
import useUserEmail from '../../../../hooks/useUserEmail';
import { toast } from 'react-toastify';
import styles from '../AllowancesCalculations.module.css';

const AllowancesGranular = ({ dataAgreementId, tradepersonId }) => {
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [data, setData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const userEmail = useUserEmail(); // For logging or audit if needed

  // 1. Fetch table names on mount
  useEffect(() => {
    const loadTableNames = async () => {
      setLoadingTables(true);
      setError(null);
      try {
        // fetchAllowancesGranularTables() should return something like:
        // [ { table_name: "dim_datacarptmanallowance" }, { table_name: "dim_dataotimemealallowance" }, ... ]
        const tables = await fetchAllowancesGranularTables();
        setTableNames(tables.map((t) => t.table_name) || []);
      } catch (err) {
        setError(err.message || 'Failed to load Allowances tables.');
        toast.error(err.message || 'Failed to load Allowances tables.');
      } finally {
        setLoadingTables(false);
      }
    };

    loadTableNames();
  }, []);

  // 2. Fetch data when selectedTable, dataAgreementId, or tradepersonId changes
  useEffect(() => {
    if (!selectedTable) {
      setData([]);
      return;
    }

    const loadData = async () => {
      setLoadingData(true);
      setError(null);
      try {
        const fetchedData = await fetchGranularData(selectedTable, dataAgreementId, tradepersonId);
        setData(fetchedData);
      } catch (err) {
        setError(err.message || 'Failed to load data.');
        toast.error(err.message || 'Failed to load data.');
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, [selectedTable, dataAgreementId, tradepersonId]);

  // 3. Handler for table selection
  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName === selectedTable ? null : tableName);
  };

  // 4. Handler for updating data
  const handleUpdate = async (rowIndex, columnKey, newValue) => {
    // Look up the selected table's config
    const tableConfig = TABLE_COLUMNS[selectedTable];
    if (!tableConfig) {
      setError(`Configuration for table "${selectedTable}" not found.`);
      toast.error(`Configuration for table "${selectedTable}" not found.`);
      return;
    }

    const primaryKey = tableConfig.primaryKey;
    const primaryKeyValue = data[rowIndex][primaryKey];

    try {
      const response = await updateTable({
        table_name: selectedTable,
        primary_key_column: primaryKey,
        primary_key_value: primaryKeyValue,
        column_name: columnKey,
        new_value: newValue,
        user_email: userEmail,
      });

      if (response.success) {
        // Success toast + optimistic UI update
        toast.success(response.message || 'Successfully updated!');
        const updatedData = [...data];
        updatedData[rowIndex][columnKey] = newValue;
        setData(updatedData);
      } else {
        toast.error(response.message || 'Failed to update the table.');
        setError(response.message || 'Failed to update the table.');
      }
    } catch (err) {
      toast.error(err.message || 'An unexpected error occurred.');
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div>
      <h2>Allowances Granular Data</h2>

      {loadingTables && <p>Loading tables...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {/* Table Selection Buttons */}
      <div className={styles.tableButtonContainer}>
        {tableNames.map((tbl) => (
          <button
            key={tbl}
            onClick={() => handleTableSelect(tbl)}
            className={`${styles.tableButton} ${selectedTable === tbl ? styles.active : ''}`}
          >
            {tbl}
          </button>
        ))}
      </div>

      {/* Editable Table */}
      {selectedTable && (
        <div className={styles.tableContainer}>
          <h3>Editing: {selectedTable}</h3>
          {loadingData ? (
            <p>Loading data...</p>
          ) : (
            <>
              {data.length === 0 ? (
                <p>No data found for {selectedTable}.</p>
              ) : (
                <EditableTable
                  columns={TABLE_COLUMNS[selectedTable].columns}
                  data={data}
                  primaryKey={TABLE_COLUMNS[selectedTable].primaryKey}
                  editableColumns={TABLE_COLUMNS[selectedTable].editable}
                  onUpdate={handleUpdate}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

AllowancesGranular.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

AllowancesGranular.defaultProps = {
  tradepersonId: null,
};

export default AllowancesGranular;
