// src/admin_elements/calculations/Superannuation/sections/SuperannuationTopLevel.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchSuperannuationTopLevel } from '../../../../services/api/fetchSuperannuationTopLevel';
import styles from '../SuperannuationCalculations.module.css'; // Ensure CSS is correctly referenced

const SuperannuationTopLevel = ({ dataAgreementId, quarterDate }) => {
  const [highData, setHighData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadHighLevelData();
    }

  }, [dataAgreementId, quarterDate]); // Trigger when dataAgreementId or quarterDate changes

  const loadHighLevelData = async () => {
    setLoading(true);
    setError(null);
    setHighData([]);

    try {
      const data = await fetchSuperannuationTopLevel(dataAgreementId, quarterDate);
      setHighData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>High-Level Superannuation Summary</h3>

      {loading && <p>Loading high-level superannuation data...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {highData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Superannuation Item</th>
              <th>No. of Units Per Annum</th>
              <th>Level 1 Class</th>
              <th>Level 2 Class</th>
              <th>Level 3 Class</th>
            </tr>
          </thead>
          <tbody>
            {highData.map((row, index) => (
              <tr key={`${row.quarterdate}-${row.superannuation_item}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.superannuation_item}</td>
                <td>{row.no_of_units_per_annum}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && !error && <p>No high-level superannuation data found.</p>
      )}
    </div>
  );
};

SuperannuationTopLevel.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quarterDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SuperannuationTopLevel;
