// src/admin_elements/Calculations/Accrual/AccrualGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const AccrualGraph = () => {
  return <CalculationGraph calcType="Accruals" />;
};

export default AccrualGraph;
