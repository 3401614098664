// src/admin_elements/ParsedDataDisplay.jsx

import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles/ParsedDataDisplay.module.css';

const ParsedDataDisplay = ({ sheetName, data }) => {
  if (!Array.isArray(data)) {
    return (
      <div className={styles.sheetContainer}>
        <h4>{sheetName}</h4>
        <p>No data available to display.</p>
      </div>
    );
  }


  if (data.length === 0) {
    return (
      <div className={styles.sheetContainer}>
        <h4>{sheetName}</h4>
        <p>No records found.</p>
      </div>
    );
  }

  return (
    <div className={styles.sheetContainer}>
      <h4>{sheetName}</h4>
      <table className={styles.dataTable}>
        <thead>
          <tr>
            {Object.keys(data[0]).map((key, idx) => (
              <th key={`${sheetName}-header-${idx}`}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIdx) => (
            <tr key={`${sheetName}-row-${rowIdx}`}>
              {Object.values(row).map((value, colIdx) => (
                <td key={`${sheetName}-row-${rowIdx}-col-${colIdx}`}>
                  {value !== null && value !== undefined ? value : ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ParsedDataDisplay.propTypes = {
  sheetName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ParsedDataDisplay;
