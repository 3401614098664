// src/services/api/fetchRatesAllPurpose.js

import { supabase } from '../supabaseClient';

/**
 * Fetch high-level rates data from the Supabase view vw_rates_all_purpose
 *
 * @param {number|string} dataAgreementId - The Data Agreement ID selected from QuickNavigationPanel
 * @param {string} quarterDate - The Quarter Date selected from QuickNavigationPanel (format: YYYY-MM-DD)
 * @returns {Promise<{ data: Array, error: Error|null }>} - Returns an object containing the data array and an error if any
 */
export const fetchRatesAllPurpose = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    return {
      data: [],
      error: new Error('Data Agreement ID and Quarter Date are required.'),
    };
  }

  try {
    const { data, error } = await supabase
      .from('vw_rates_all_purpose_v1') // Your Supabase view name
      .select('quarterdate,level1_allpurposerate,level2_allpurposerate,level3_allpurposerate')
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate);

    if (error) {
      throw error;
    }

    if (!data || data.length === 0) {
      return {
        data: [],
        error: new Error('No rates found for the selected Data Agreement and Quarter Date.'),
      };
    }

    return { data, error: null };
  } catch (error) {
    console.error('Error fetching rates:', error.message);
    return { data: [], error };
  }
};
