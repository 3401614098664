// src/pages/About.jsx
import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto text-center py-16">
      <h1 className="text-4xl font-bold text-blue-900 mb-8">About CMC Analytics</h1>
      <p className="text-lg text-gray-700">
        CMC Analytics specializes in delivering data-driven insights for the construction industry. 
        Our platform provides in-depth analysis of employee annual salary data, productive hours, 
        and labor costs, enabling companies to make informed decisions, optimize their workforce, 
        and streamline operations. By leveraging advanced analytics, we help construction companies 
        reduce overheads, improve budgeting accuracy, and stay competitive in the fast-paced industry.Created by DataSolve
      </p>
    </div>
  );
};

export default About;
