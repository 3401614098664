// src/services/api/fetchAvailableYears.js

import { supabase } from '../supabaseClient';

/**
 * Fetches a list of available years from the vw_available_years_rates view.
 *
 * @returns {Promise<{ data: Array<number>, error: Error|null }>}
 */
export const fetchAvailableYears = async () => {
  console.log('Fetching available years from Supabase...');

  try {
    const { data, error, status } = await supabase
      .from('vw_available_years_rates') // New view name
      .select('year') // Selecting the 'year' field

    if (error && status !== 406) {
      console.error('Supabase query error:', error);
      throw error;
    }

    if (!data || data.length === 0) {
      console.warn('No available years found in the database.');
      return {
        data: [],
        error: new Error('No available years found in the database.'),
      };
    }

    // Extract years into an array of numbers
    const years = data.map(item => Number(item.year)).sort((a, b) => a - b);
    console.log('Available Years:', years);

    return { data: years, error: null };
  } catch (error) {
    console.error('Error in fetchAvailableYears:', error.message);
    return { data: [], error };
  }
};
