// src/services/api/fetchSuperableGranularTables.js

import { supabase } from '../supabaseClient';

/**
 * Fetches table names that belong to the "Superable" classification
 * from vw_returntablenames using ILIKE on classification.
 *
 * @returns {Promise<Array>} An array of objects containing table names and their primary key columns.
 */
export const fetchSuperableGranularTables = async () => {
  try {
    const { data, error } = await supabase
      .from('vw_returntablenames')
      .select('table_name, primary_key_column') // Ensure you have primary_key_column
      .ilike('classification', '%Superable%'); // Adjust based on your classification field

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Superable granular table names:', error.message);
    throw error;
  }
};
