// src/admin_elements/sideNav/SideNav.jsx

import React from 'react';
import {
  FaDatabase,
  FaUser,
  FaDollarSign,
  FaMoneyCheck,
  FaHandHoldingUsd,
  FaCalculator,
  FaClipboardCheck,
  FaRegBuilding,
  FaPercentage,
  FaFlagCheckered
} from 'react-icons/fa';

import styles from './SideNav.module.css';

/*
  Renamed `activeSection` to `selectedSection` so it matches Admin's usage:
  <SideNav selectedSection={selectedSection} onSelect={setSelectedSection} />
*/

const SideNav = ({ selectedSection, onSelect }) => {
  // Toggle the same section off if clicked again; otherwise set new section.
  const handleClick = (section) => {
    onSelect(section === selectedSection ? '' : section);
  };

  return (
    <div className={styles.sideNav}>
      {/* DATA SECTION */}
      <div className={styles.navSection}>
        <div className={styles.sectionHeader}>Data</div>

        <div
          className={
            selectedSection === 'dataAgreement'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('dataAgreement')}
        >
          <FaDatabase size={24} />
          <span className={styles.navText}>Data Agreement</span>
        </div>

        <div
          className={
            selectedSection === 'tradeperson'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('tradeperson')}
        >
          <FaUser size={24} />
          <span className={styles.navText}>Tradeperson</span>
        </div>
      </div>

      <hr className={styles.divider} />

      {/* CALCULATIONS SECTION */}
      <div className={styles.navSection}>
        <div className={styles.sectionHeader}>Calculations</div>

        <div
          className={
            selectedSection === 'calcRates'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcRates')}
        >
          <FaDollarSign size={24} />
          <span className={styles.navText}>Rates</span>
        </div>

        <div
          className={
            selectedSection === 'calcWages'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcWages')}
        >
          <FaMoneyCheck size={24} />
          <span className={styles.navText}>Wages</span>
        </div>

        <div
          className={
            selectedSection === 'calcAllowances'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcAllowances')}
        >
          <FaHandHoldingUsd size={24} />
          <span className={styles.navText}>Allowances</span>
        </div>

        <div
          className={
            selectedSection === 'calcAccrual'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcAccrual')}
        >
          <FaCalculator size={24} />
          <span className={styles.navText}>Accrual</span>
        </div>

        <div
          className={
            selectedSection === 'calcSuperable'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcSuperable')}
        >
          <FaClipboardCheck size={24} />
          <span className={styles.navText}>Superable</span>
        </div>

        <div
          className={
            selectedSection === 'calcSuperannuation'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcSuperannuation')}
        >
          <FaRegBuilding size={24} />
          <span className={styles.navText}>Superannuation</span>
        </div>

        <div
          className={
            selectedSection === 'calcOncosts'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcOncosts')}
        >
          <FaPercentage size={24} />
          <span className={styles.navText}>Oncosts</span>
        </div>

        <div
          className={
            selectedSection === 'calcFinal'
              ? styles.activeIcon
              : styles.navIcon
          }
          onClick={() => handleClick('calcFinal')}
        >
          <FaFlagCheckered size={24} />
          <span className={styles.navText}>Final</span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
