// src/admin_elements/calculations/Wages/sections/WagesMidLevel.jsx
import React, { useState, useEffect } from 'react';
import { fetchWagesMidLevel } from '../../../../services/api/fetchWagesMidLevel';
import styles from '../WagesCalculations.module.css';

const WagesMidLevel = ({ dataAgreementId, quarterDate }) => {
  const [midData, setMidData] = useState([]);
  const [midError, setMidError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadMidLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadMidLevelData = async () => {
    setLoading(true);
    setMidError(null);
    setMidData([]);

    const { data, error } = await fetchWagesMidLevel(dataAgreementId, quarterDate);

    if (error) {
      setMidError(error);
      setLoading(false);
      return;
    }

    setMidData(data);
    setLoading(false);
  };

  return (
    <div>
      <h3>Mid-Level Wages Calculations</h3>

      {loading && <p>Loading mid-level wages...</p>}

      {midError && (
        <p className={styles.error}>
          {midError.message || 'Error fetching mid-level wages data.'}
        </p>
      )}

      {midData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Wages Item</th>
              <th>Units/Annum</th>
              <th>Factor</th>
              <th>Level1 Class</th>
              <th>Level2 Class</th>
              <th>Level3 Class</th>
            </tr>
          </thead>
          <tbody>
            {midData.map((row, idx) => (
              <tr key={`${row.quarterdate}-${idx}`}>
                <td>{row.quarterdate}</td>
                <td>{row.wages_item}</td>
                <td>{row.no_of_units_per_annum}</td>
                <td>{row.factor}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading &&
        !midError && <p>No mid-level wages data found for these criteria.</p>
      )}
    </div>
  );
};

export default WagesMidLevel;
