// src/admin_elements/Calculations/CalculationGraph.jsx

import React, { useState, useEffect, useMemo, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import YearSelector from '../../components/YearSelector';
import { fetchAvailableYears } from '../../services/api/fetchAvailableYears';
import { fetchMasterCalcYear } from '../../services/api/fetchMasterCalcYear';
import { fetchDataAgreementGraph } from '../../services/api/fetchDataAgreementGraph';

// Lazy-load your line chart component
const CalculationLineChart = lazy(() => import('../../components/Charts/CalculationLineChart'));

import styles from './CalculationGraph.module.css';

const CalculationGraph = ({ calcType }) => {
  // 1. Year and Preset Option States
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [limitOption, setLimitOption] = useState('ALL'); // 'ALL' | 'TOP5' | 'BOTTOM5'

  // Loading & error states for years
  const [loadingYears, setLoadingYears] = useState(false);
  const [errorYears, setErrorYears] = useState(null);

  // 2. Data Agreement Multi-Select States
  const [allAgreements, setAllAgreements] = useState([]);
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [loadingAgreements, setLoadingAgreements] = useState(false);
  const [errorAgreements, setErrorAgreements] = useState(null);

  // 3. Chart Data States
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [errorData, setErrorData] = useState(null);

  // ---------------------------------------------------------------------------
  // A. Fetch Available Years on Mount
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const getYears = async () => {
      setLoadingYears(true);
      setErrorYears(null);
      try {
        const { data: years, error } = await fetchAvailableYears();
        if (error) {
          console.error('Error fetching available years:', error);
          setErrorYears('Failed to fetch available years.');
        } else {
          setAvailableYears(years || []);
          if (years && years.length > 0) {
            // Default to the latest year
            setSelectedYear(years[years.length - 1]);
            console.log('Available Years:', years);
          }
        }
      } catch (err) {
        console.error('Unexpected error fetching years:', err);
        setErrorYears('An unexpected error occurred while fetching years.');
      } finally {
        setLoadingYears(false);
      }
    };
    getYears();
  }, []);

  // ---------------------------------------------------------------------------
  // B. Fetch Data Agreements on Mount
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const loadAgreements = async () => {
      setLoadingAgreements(true);
      setErrorAgreements(null);
      try {
        const agreements = await fetchDataAgreementGraph();
        console.log('Fetched Agreements:', agreements);
        // Transform to react-select options
        const agreementOptions = (agreements || []).map((agreement) => ({
          value: agreement.dataagreementid,
          label: agreement['Short Title'] || `Agreement #${agreement.dataagreementid}`,
        }));
        setAllAgreements(agreementOptions);
      } catch (error) {
        console.error('Error fetching data agreements:', error);
        setErrorAgreements('Failed to fetch data agreements.');
      } finally {
        setLoadingAgreements(false);
      }
    };
    loadAgreements();
  }, []);

  // ---------------------------------------------------------------------------
  // C. Fetch Data Whenever Year / Limit Option / Data Agreements / calcType Changes
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!selectedYear) return;

    const loadData = async () => {
      setLoadingData(true);
      setErrorData(null);

      try {
        // If 'ALL', we use the selectedAgreements for filtering; otherwise null
        const dataAgreementIds =
          limitOption === 'ALL'
            ? selectedAgreements.map((item) => Number(item.value))
            : null;

        console.log('Fetching data with parameters:', {
          dataAgreementIds,
          year: selectedYear,
          calcType,
          limitOption,
        });

        const { data: fetchedRows, error } = await fetchMasterCalcYear({
          dataAgreementIds,
          year: selectedYear,
          calcType, // from prop
          limitOption,
        });

        if (error) {
          console.error('Error from masterCalcYear:', error);
          setErrorData(error.message || 'Error fetching data.');
          setData([]);
        } else {
          console.log('Fetched Rows:', fetchedRows);
          setData(fetchedRows || []);
        }
      } catch (err) {
        console.error('Fetch Data Error:', err);
        setErrorData(err.message || 'An unexpected error occurred. Please try again.');
        setData([]);
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, [selectedYear, selectedAgreements, limitOption, calcType]);

  // ---------------------------------------------------------------------------
  // D. Process Data for the Chart (Sorting by total sum of average_value)
  // ---------------------------------------------------------------------------
  const processedData = useMemo(() => {
    if (!data.length) return [];

    // 1. Unique quarterdates
    const uniqueQDates = [...new Set(data.map((row) => row.quarterdate))].sort();

    // 2. Map dataagreementid -> short_title
    const dataAgreementMap = {};
    data.forEach((row) => {
      dataAgreementMap[row.dataagreementid] =
        row.short_title || `Agreement #${row.dataagreementid}`;
    });

    // 3. Compute total values for each dataagreementid
    const totalValueMap = {};
    Object.keys(dataAgreementMap).forEach((id) => {
      totalValueMap[id] = 0;
    });
    data.forEach((row) => {
      const val = parseFloat(row.average_value);
      if (!isNaN(val)) {
        totalValueMap[row.dataagreementid] += val;
      }
    });

    // 4. Sort dataagreementids by total value (desc)
    const sortedIds = Object.keys(dataAgreementMap)
      .map((id) => Number(id))
      .sort((a, b) => totalValueMap[b] - totalValueMap[a]);

    // 5. Create pivot structure
    const pivot = {};
    uniqueQDates.forEach((qd) => {
      pivot[qd] = { quarterdate: qd };
      sortedIds.forEach((id) => {
        const label = `${dataAgreementMap[id]} (#${id})`;
        pivot[qd][label] = null; // init
      });
    });

    // 6. Populate pivot
    data.forEach((row) => {
      const qd = row.quarterdate;
      const id = row.dataagreementid;
      const label = `${dataAgreementMap[id]} (#${id})`;
      const val = parseFloat(row.average_value);
      if (!isNaN(val)) {
        pivot[qd][label] = val;
      }
    });

    // 7. Convert pivot to array
    return uniqueQDates.map((qd) => pivot[qd]);
  }, [data]);

  // Extract line keys for the chart
  const uniqueShortTitles = useMemo(() => {
    if (!processedData.length) return [];
    return Object.keys(processedData[0]).filter((key) => key !== 'quarterdate');
  }, [processedData]);

  // ---------------------------------------------------------------------------
  // E. Handle Preset Option / Data Agreement Selection
  // ---------------------------------------------------------------------------
  const handlePresetChange = (option) => {
    setLimitOption(option.value);
    if (option.value !== 'ALL') {
      // Clear selected agreements
      setSelectedAgreements([]);
    }
  };

  const handleDataAgreementChange = (selectedOptions) => {
    setSelectedAgreements(selectedOptions || []);
  };

  // ---------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{`${calcType} Graph`}</h2>

      {/* Filter Controls */}
      <div className={styles.filtersSection}>
        {/* Year Selector */}
        <div className={styles.filterItem}>
          <label>Year:</label>
          {loadingYears ? (
            <div>Loading years...</div>
          ) : errorYears ? (
            <div className={styles.error}>{errorYears}</div>
          ) : (
            <YearSelector
              years={availableYears}
              selectedYear={selectedYear}
              onChange={(year) => setSelectedYear(year)}
            />
          )}
        </div>

        {/* Preset (ALL/TOP5/BOTTOM5) */}
        <div className={styles.filterItem}>
          <label>Preset Option:</label>
          <Select
            value={{
              value: limitOption,
              label:
                limitOption === 'ALL' ? 'ALL' : limitOption === 'TOP5' ? 'Top 5' : 'Bottom 5',
            }}
            onChange={handlePresetChange}
            options={[
              { value: 'ALL', label: 'ALL' },
              { value: 'TOP5', label: 'Top 5' },
              { value: 'BOTTOM5', label: 'Bottom 5' },
            ]}
          />
        </div>

        {/* Data Agreement Multi-Select */}
        <div className={styles.filterItem}>
          <label>Data Agreement(s):</label>
          {loadingAgreements ? (
            <div>Loading agreements...</div>
          ) : errorAgreements ? (
            <div className={styles.error}>{errorAgreements}</div>
          ) : (
            <Select
              isMulti
              isDisabled={limitOption !== 'ALL'}
              value={selectedAgreements}
              options={allAgreements}
              onChange={handleDataAgreementChange}
              placeholder={limitOption === 'ALL' ? 'Select Data Agreements...' : 'Disabled'}
            />
          )}
        </div>
      </div>

      {/* Loading & Error */}
      {loadingData && <div>Loading Data...</div>}
      {errorData && <div className={styles.error}>{errorData}</div>}

      {/* Chart */}
      {!loadingData && !errorData && processedData.length > 0 && (
        <div className={styles.chartContainer}>
          <Suspense fallback={<div>Loading Chart...</div>}>
            <CalculationLineChart data={processedData} selectedShortTitles={uniqueShortTitles} />
          </Suspense>
        </div>
      )}

      {/* No Data */}
      {!loadingData && !errorData && processedData.length === 0 && selectedYear && (
        <div>No data found for your selection.</div>
      )}
    </div>
  );
};

CalculationGraph.propTypes = {
  calcType: PropTypes.string.isRequired,
};

export default CalculationGraph;
