// src/admin_elements/Calculations/Allowances/AllowancesGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const AllowancesGraph = () => {
  return <CalculationGraph calcType="Allowances" />;
};

export default AllowancesGraph;
