// src/admin_elements/calculations/Rates/RatesCalculations.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RatesCalculations.module.css';

// Sub-components
import RatesTopLevel from './sections/RatesTopLevel';
import RatesMidLevel from './sections/RatesMidLevel';
import RatesGranular from './sections/RatesGranular';

const RatesCalculations = ({ dataAgreementId, quarterDate, tradepersonId }) => {
  // Accordion toggles
  const [topOpen, setTopOpen] = useState(true);
  const [midOpen, setMidOpen] = useState(false);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>Rates Calculations</h2>

      {/* Top-Level Summary */}
      <div className={styles.accordionItem}>
        <div 
          className={styles.accordionHeader} 
          onClick={() => setTopOpen(!topOpen)}
        >
          <span>Top-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <RatesTopLevel 
              dataAgreementId={dataAgreementId} 
              quarterDate={quarterDate} 
              tradepersonId={tradepersonId} // Pass tradepersonId
            />
          </div>
        )}
      </div>

      {/* Mid-Level */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setMidOpen(!midOpen)}
        >
          <span>Middle-Level Calculations</span>
          <span className={styles.toggleIcon}>{midOpen ? '-' : '+'}</span>
        </div>
        {midOpen && (
          <div className={styles.accordionContent}>
            <RatesMidLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate}
              tradepersonId={tradepersonId} // Pass tradepersonId
            />
          </div>
        )}
      </div>

      {/* Granular */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setGranOpen(!granOpen)}
        >
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <RatesGranular
              dataAgreementId={dataAgreementId}
              tradepersonId={tradepersonId} // Pass tradepersonId
            />
          </div>
        )}
      </div>
    </div>
  );
};

RatesCalculations.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quarterDate: PropTypes.string, // Adjust type as needed
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RatesCalculations.defaultProps = {
  quarterDate: null,
  tradepersonId: null,
};

export default RatesCalculations;
