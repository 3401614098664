// src/admin_elements/calculations/Wages/sections/WagesTopLevel.jsx
import React, { useState, useEffect } from 'react';
import { fetchWagesSummary } from '../../../../services/api/fetchWagesSummary';
import styles from '../WagesCalculations.module.css';

const WagesTopLevel = ({ dataAgreementId, quarterDate }) => {
  const [wagesData, setWagesData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadTopLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadTopLevelData = async () => {
    setLoading(true);
    setError(null);
    setWagesData([]);

    const { data, error } = await fetchWagesSummary(dataAgreementId, quarterDate);
    if (error) {
      setError(error);
      setLoading(false);
      return;
    }
    setWagesData(data);
    setLoading(false);
  };

  if (!dataAgreementId || !quarterDate) {
    return <p>Please select a Data Agreement and Quarter Date.</p>;
  }

  return (
    <div>
      {loading && <p>Loading top-level wages...</p>}
      {error && <p className={styles.error}>{error.message}</p>}

      {wagesData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Level1 Class</th>
              <th>Level2 Class</th>
              <th>Level3 Class</th>
              {/* Add columns as needed */}
            </tr>
          </thead>
          <tbody>
            {wagesData.map((row, index) => (
              <tr key={`${row.quarterdate}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading &&
        !error && <p>No wages summary found for these criteria.</p>
      )}
    </div>
  );
};

export default WagesTopLevel;
