// src/services/api/fetchAccrualsGranularTables.js

import { supabase } from '../supabaseClient';

/**
 * Fetches table names that belong to the "Accrual" classification
 * from vw_returntablenames using ilike on classification.
 *
 * @returns {Promise<Array>} An array of objects containing table names and their primary key columns.
 */
export const fetchAccrualsGranularTables = async () => {
  try {
    const { data, error } = await supabase
      .from('vw_returntablenames')
      .select('table_name') // Ensure you have primary_key_column
      .ilike('classification', '%Accrual%'); // Adjust based on your classification field

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Accruals granular table names:', error.message);
    throw error;
  }
};
