// src/admin_elements/calculations/Wages/sections/WagesGranular.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditableTable from '../../../../components/EditableTable';
import { fetchWagesGranularTables } from '../../../../services/api/fetchWagesGranularTables';
import { fetchGranularData } from '../../../../services/api/fetchGranularData';
import { updateTable } from '../../../../services/api/update_table';
import { TABLE_COLUMNS } from '../../../../services/tableColumns';
import useUserEmail from '../../../../hooks/useUserEmail';
import { toast } from 'react-toastify';
import styles from '../WagesCalculations.module.css';

const WagesGranular = ({ dataAgreementId, tradepersonId }) => {
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [data, setData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const userEmail = useUserEmail(); // Retrieve the user's email

  // 1. Fetch table names on mount
  useEffect(() => {
    const loadTableNames = async () => {
      setLoadingTables(true);
      setError(null);
      try {
        // fetchWagesGranularTables should return something like:
        // [ { table_name: "dim_datahours" }, { table_name: "dim_paidbreaks" } ... ]
        const tables = await fetchWagesGranularTables();
        setTableNames(tables.map((t) => t.table_name) || []);
      } catch (err) {
        setError(err.message || 'Failed to load table names.');
        toast.error(err.message || 'Failed to load table names.');
      } finally {
        setLoadingTables(false);
      }
    };

    loadTableNames();
  }, []);

  // 2. Fetch data when selectedTable, dataAgreementId, or tradepersonId changes
  useEffect(() => {
    if (!selectedTable) {
      setData([]);
      return;
    }

    const loadData = async () => {
      setLoadingData(true);
      setError(null);
      try {
        const fetchedData = await fetchGranularData(selectedTable, dataAgreementId, tradepersonId);
        setData(fetchedData);
      } catch (err) {
        setError(err.message || 'Failed to load table data.');
        toast.error(err.message || 'Failed to load table data.');
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, [selectedTable, dataAgreementId, tradepersonId]);

  // 3. Handler for table selection
  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName === selectedTable ? null : tableName);
  };

  // 4. Handler for updating data (includes toast notifications)
  const handleUpdate = async (rowIndex, columnKey, newValue) => {
    const tableConfig = TABLE_COLUMNS[selectedTable];
    if (!tableConfig) {
      setError(`Configuration for table "${selectedTable}" not found.`);
      toast.error(`Configuration for table "${selectedTable}" not found.`);
      return;
    }

    const primaryKey = tableConfig.primaryKey;
    const primaryKeyValue = data[rowIndex][primaryKey];

    try {
      const response = await updateTable({
        table_name: selectedTable,
        primary_key_column: primaryKey,
        primary_key_value: primaryKeyValue,
        column_name: columnKey,
        new_value: newValue,
        user_email: userEmail, // Pass user email for logging
      });

      if (response.success) {
        // Show success toast with the message from the Supabase function
        toast.success(response.message || 'Successfully updated!');

        // Optimistically update the local UI data
        const updatedData = [...data];
        updatedData[rowIndex][columnKey] = newValue;
        setData(updatedData);
      } else {
        setError(response.message || 'Failed to update the table.');
        toast.error(response.message || 'Failed to update the table.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
      toast.error(err.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div>
      <h2>Wages Granular Data</h2>
      {loadingTables && <p>Loading tables...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {/* Table Selection Buttons */}
      <div className={styles.tableButtonContainer}>
        {tableNames.map((tbl) => (
          <button
            key={tbl}
            onClick={() => handleTableSelect(tbl)}
            className={`${styles.tableButton} ${selectedTable === tbl ? styles.active : ''}`}
          >
            {tbl}
          </button>
        ))}
      </div>

      {/* Editable Table */}
      {selectedTable && (
        <div className={styles.tableContainer}>
          <h3>Editing: {selectedTable}</h3>
          {loadingData ? (
            <p>Loading data...</p>
          ) : (
            <EditableTable
              columns={TABLE_COLUMNS[selectedTable].columns}
              data={data}
              primaryKey={TABLE_COLUMNS[selectedTable].primaryKey}
              editableColumns={TABLE_COLUMNS[selectedTable].editable}
              onUpdate={handleUpdate}
            />
          )}
        </div>
      )}
    </div>
  );
};

WagesGranular.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

WagesGranular.defaultProps = {
  tradepersonId: null,
};

export default WagesGranular;
