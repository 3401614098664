// src/admin_elements/Calculations/Wages/WagesGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const WagesGraph = () => {
  return <CalculationGraph calcType="Wages" />;
};

export default WagesGraph;
