// src/admin_elements/calculations/Accruals/sections/AccrualTopLevel.jsx

import React, { useState, useEffect } from 'react';
import { fetchAccrualsTopLevel } from '../../../../services/api/fetchAccrualsTopLevel';
import styles from '../AccrualCalculations.module.css'; // Ensure CSS is correctly referenced

const AccrualTopLevel = ({ dataAgreementId, quarterDate }) => {
  const [topData, setTopData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadTopLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadTopLevelData = async () => {
    setLoading(true);
    setError(null);
    setTopData([]);

    try {
      const data = await fetchAccrualsTopLevel(dataAgreementId, quarterDate);
      setTopData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>Top-Level Accruals Summary</h3>

      {loading && <p>Loading top-level accruals...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {topData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Level 1 Class</th>
              <th>Level 2 Class</th>
              <th>Level 3 Class</th>
            </tr>
          </thead>
          <tbody>
            {topData.map((row, index) => (
              <tr key={`${row.quarterdate}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && !error && <p>No top-level accruals data found for the selected quarter.</p>
      )}
    </div>
  );
};

export default AccrualTopLevel;
