// src/admin_elements/calculations/Final/FinalGraph.jsx

import React from 'react';
import PropTypes from 'prop-types';
import CalculationGraph from '../CalculationGraph';

const FinalGraph = ({ calcType }) => {
  // If calcType is null, we might show a placeholder
  if (!calcType) {
    return <p>Please select a Final Calculation type on the left.</p>;
  }

  return (
    <div>
      {/* Pass calcType to CalculationGraph, which will call fetchMasterCalcYear */}
      <CalculationGraph calcType={calcType} />
    </div>
  );
};

FinalGraph.propTypes = {
  calcType: PropTypes.string,
};

FinalGraph.defaultProps = {
  calcType: null,
};

export default FinalGraph;
