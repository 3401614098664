// src/services/api/fetchAllowancesMidLevel.js

import { supabase } from '../supabaseClient';

/**
 * Fetch mid-level allowances data from vw_allowance_v1
 *
 * @param {number|string} dataAgreementId
 * @param {string} quarterDate (format YYYY-MM-DD)
 * @returns {Promise<Array>} - An array of mid-level allowance details.
 */
export const fetchAllowancesMidLevel = async (dataAgreementId, quarterDate) => {
  if (!dataAgreementId || !quarterDate) {
    throw new Error('dataAgreementId and quarterDate are required.');
  }

  try {
    const { data, error } = await supabase
      .from('vw_allowance_v1')
      .select(`
        quarterdate,
        allowances_flat,
        allowances_order,
        typename,
        no_of_units_per_annum,
        amount,
        level1_class, 
        level2_class, 
        level3_class
      `)
      .eq('dataagreementid', dataAgreementId)
      .eq('quarterdate', quarterDate)
      .order('allowances_order', { ascending: true });

    if (error) throw error;

    return data || [];
  } catch (error) {
    console.error('Error fetching Allowances mid-level data:', error.message);
    throw error;
  }
};
