// src/services/api/helper/dateUtils.js

/**
 * Converts a year and quarter into the start date of that quarter.
 *
 * @param {number} year - The selected year.
 * @param {string} quarter - The selected quarter ('Q1', 'Q2', 'Q3', 'Q4').
 * @returns {string} - The start date of the quarter in 'YYYY-MM-DD' format.
 */
export const getStartDateOfQuarter = (year, quarter) => {
    const quarterStartMonths = {
      Q1: '01', // January
      Q2: '04', // April
      Q3: '07', // July
      Q4: '10', // October
    };
  
    const month = quarterStartMonths[quarter] || '01';
    return `${year}-${month}-01`; // YYYY-MM-DD
  };
  