// src/components/EditableTable.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/EditableTable.module.css'; // Ensure this CSS module exists
import { FiEdit } from 'react-icons/fi'; // Importing the Feather Edit icon

const EditableTable = ({
  columns,
  data,
  primaryKey,
  editableColumns,
  onUpdate, // Function to handle data updates
}) => {
  const [editingCell, setEditingCell] = useState({ rowIndex: null, columnKey: null });
  const [editedValue, setEditedValue] = useState('');
  const [error, setError] = useState(null);

  const handleEditClick = (rowIndex, columnKey, currentValue) => {
    setEditingCell({ rowIndex, columnKey });
    setEditedValue(currentValue);
    setError(null);
  };

  const handleSaveClick = () => {
    if (editedValue.trim() === '') {
      setError('Value cannot be empty.');
      return;
    }
    onUpdate(editingCell.rowIndex, editingCell.columnKey, editedValue);
    setEditingCell({ rowIndex: null, columnKey: null });
    setEditedValue('');
  };

  const handleCancelClick = () => {
    setEditingCell({ rowIndex: null, columnKey: null });
    setEditedValue('');
    setError(null);
  };

  return (
    <div>
      {error && <p className={styles.error}>{error}</p>}
      <table className={styles.editableTable}>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col}>
                {col
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={row[primaryKey]}>
              {columns.map((col) => (
                <td key={col}>
                  {editingCell.rowIndex === rowIndex && editingCell.columnKey === col ? (
                    <div className={styles.editingCell}>
                      <input
                        type="text"
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                        className={styles.inputField}
                      />
                      <button onClick={handleSaveClick} className={styles.saveButton}>
                        Save
                      </button>
                      <button onClick={handleCancelClick} className={styles.cancelButton}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className={styles.cellContent}>
                      {String(row[col])}
                      {editableColumns.includes(col) && (
                        <button
                          onClick={() => handleEditClick(rowIndex, col, row[col])}
                          className={styles.editButton}
                          aria-label={`Edit ${col}`}
                        >
                          <FiEdit />
                        </button>
                      )}
                    </div>
                  )}
                </td>
              ))}
              <td>
                {/* Placeholder for future actions like Delete */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

EditableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  primaryKey: PropTypes.string.isRequired,
  editableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdate: PropTypes.func.isRequired, // Function signature: (rowIndex, columnKey, newValue) => void
};

export default EditableTable;
