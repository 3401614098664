// src/admin_elements/calculations/Accruals/sections/AccrualGranular.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditableTable from '../../../../components/EditableTable';
import { fetchAccrualsGranularTables } from '../../../../services/api/fetchAccrualsGranularTables';
import { fetchGranularData } from '../../../../services/api/fetchGranularData';
import { updateTable } from '../../../../services/api/update_table';
import { TABLE_COLUMNS } from '../../../../services/tableColumns'; // Ensure correct path
import useUserEmail from '../../../../hooks/useUserEmail';
import { toast } from 'react-toastify';
import styles from '../AccrualCalculations.module.css';

const AccrualGranular = ({ dataAgreementId, tradepersonId }) => {
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [data, setData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const userEmail = useUserEmail();

  // 1. Fetch possible table names on mount
  useEffect(() => {
    const loadTableNames = async () => {
      setLoadingTables(true);
      setError(null);
      try {
        // fetchAccrualsGranularTables() returns something like:
        // [ { table_name: "fact_leave" }, ... ]
        const tables = await fetchAccrualsGranularTables();
        setTableNames(tables.map((t) => t.table_name) || []);
      } catch (err) {
        setError(err.message || 'Failed to load table names.');
        toast.error(err.message || 'Failed to load table names.');
      } finally {
        setLoadingTables(false);
      }
    };
    loadTableNames();
  }, []);

  // 2. Whenever the user selects a table, fetch its data
  useEffect(() => {
    if (!selectedTable) {
      setData([]);
      return;
    }
    const loadData = async () => {
      setLoadingData(true);
      setError(null);
      try {
        // fetch data for the chosen table, filtered by dataAgreementId / tradepersonId
        const fetchedData = await fetchGranularData(selectedTable, dataAgreementId, tradepersonId);
        setData(fetchedData);
      } catch (err) {
        setError(err.message || 'Failed to load table data.');
        toast.error(err.message || 'Failed to load table data.');
      } finally {
        setLoadingData(false);
      }
    };
    loadData();
  }, [selectedTable, dataAgreementId, tradepersonId]);

  // 3. Toggle the selected table
  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName === selectedTable ? null : tableName);
  };

  // 4. Update handler for the editable table
  const handleUpdate = async (rowIndex, columnKey, newValue) => {
    // Instead of TABLE_COLUMNS.accruals, we use the actual selectedTable
    const tableConfig = TABLE_COLUMNS[selectedTable];
    if (!tableConfig) {
      const msg = `No configuration found for table "${selectedTable}".`;
      setError(msg);
      toast.error(msg);
      return;
    }

    const primaryKey = tableConfig.primaryKey;
    const primaryKeyValue = data[rowIndex][primaryKey];

    try {
      const response = await updateTable({
        table_name: selectedTable,
        primary_key_column: primaryKey,
        primary_key_value: primaryKeyValue,
        column_name: columnKey,
        new_value: newValue,
        user_email: userEmail, // optional if you track user changes
      });

      if (response.success) {
        toast.success(response.message || 'Updated successfully!');
        // Optimistic UI update
        const updatedData = [...data];
        updatedData[rowIndex][columnKey] = newValue;
        setData(updatedData);
      } else {
        toast.error(response.message || 'Failed to update.');
      }
    } catch (err) {
      toast.error(err.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div>
      <h2>Accruals Granular Data</h2>
      {loadingTables && <p>Loading table names...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {/* Table Buttons */}
      <div className={styles.tableButtonContainer}>
        {tableNames.map((tbl) => (
          <button
            key={tbl}
            onClick={() => handleTableSelect(tbl)}
            className={`${styles.tableButton} ${selectedTable === tbl ? styles.active : ''}`}
          >
            {tbl}
          </button>
        ))}
      </div>

      {/* Editable Table if a table is selected */}
      {selectedTable && (
        <div className={styles.tableContainer}>
          <h3>Editing: {selectedTable}</h3>

          {loadingData ? (
            <p>Loading data...</p>
          ) : data.length === 0 ? (
            <p>No data found for {selectedTable}.</p>
          ) : (
            (() => {
              const tableConfig = TABLE_COLUMNS[selectedTable];
              if (!tableConfig) {
                return <p className={styles.error}>
                  No configuration in TABLE_COLUMNS for "{selectedTable}"</p>;
              }

              return (
                <EditableTable
                  columns={tableConfig.columns}
                  data={data}
                  primaryKey={tableConfig.primaryKey}
                  editableColumns={tableConfig.editable}
                  onUpdate={handleUpdate}
                />
              );
            })()
          )}
        </div>
      )}
    </div>
  );
};

AccrualGranular.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

AccrualGranular.defaultProps = {
  tradepersonId: null,
};

export default AccrualGranular;
