// src/services/api/update_table.js

import { supabase } from '../supabaseClient';

/**
 * Updates a specific column in a given table, including the updater's email for logging.
 *
 * @param {Object} params
 * @param {string} params.table_name - The name of the table to update.
 * @param {string} params.primary_key_column - The primary key column of the table.
 * @param {string|number} params.primary_key_value - The value of the primary key for identifying the row.
 * @param {string} params.column_name - The name of the column to update.
 * @param {string|number} params.new_value - The new value to set for the column.
 * @param {string} params.user_email - The email of the user performing the update.
 * @returns {Promise<Object>} - Returns an object with 'success' and 'message' fields.
 */
export async function updateTable({
  table_name,
  primary_key_column,
  primary_key_value,
  column_name,
  new_value,
  user_email, // <-- Added the user's email
}) {
  try {
    // Call the Supabase RPC function 'update_table', including the user's email
    const { data, error } = await supabase.rpc('update_table', {
      p_table_name: table_name,
      p_primary_key_column: primary_key_column,
      p_primary_key_value: primary_key_value,
      p_column_name: column_name,
      p_new_value: new_value.toString(),
      p_user_email: user_email, // <-- Pass the email to the backend
    });

    if (error) {
      console.error('Error calling update_table:', error);
      return { success: false, message: error.message };
    }

    // 'data' typically contains the success message returned by your Postgres function
    return { success: true, message: data };
  } catch (err) {
    console.error('Unexpected error in updateTable:', err.message);
    return { success: false, message: 'An unexpected error occurred.' };
  }
}
