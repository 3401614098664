// src/admin_elements/calculations/Superannuation/SuperannuationCalculations.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types'; // For prop validation
import styles from './SuperannuationCalculations.module.css'; // Ensure CSS is correctly referenced

// Sub-sections
import SuperannuationTopLevel from './sections/SuperannuationTopLevel';
import SuperannuationGranular from './sections/SuperannuationGranular';

const SuperannuationCalculations = ({ dataAgreementId, tradepersonId, quarterDate }) => {
  // Accordion toggles
  const [topOpen, setTopOpen] = useState(true);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>Superannuation Calculations</h2>

      {/* HIGH-LEVEL */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setTopOpen((prev) => !prev)}
        >
          <span>High-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <SuperannuationTopLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate} // Pass quarterDate prop
            />
          </div>
        )}
      </div>

      {/* GRANULAR */}
      <div className={styles.accordionItem}>
        <div
          className={styles.accordionHeader}
          onClick={() => setGranOpen((prev) => !prev)}
        >
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <SuperannuationGranular
              dataAgreementId={dataAgreementId}
              tradepersonId={tradepersonId} // Pass tradepersonId prop
            />
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes for type checking and ensuring required props are provided
SuperannuationCalculations.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quarterDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SuperannuationCalculations;
