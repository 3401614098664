// src/components/YearSelector.jsx

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

/**
 * YearSelector Component
 *
 * @param {Array<number>} years - Array of available years.
 * @param {number|null} selectedYear - Currently selected year.
 * @param {function} onChange - Callback function to handle year selection.
 */
const YearSelector = ({ years, selectedYear, onChange }) => {
  const options = years.map((year) => ({
    value: year,
    label: year.toString(),
  }));

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      onChange(selectedOption.value); // Pass the selected year
    } else {
      onChange(null); // Pass null if selection is cleared
    }
  };

  // Find the currently selected option based on selectedYear
  const selectedOption = options.find((option) => option.value === selectedYear) || null;

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleChange}
      placeholder="Select Year..."
      isClearable
    />
  );
};

YearSelector.propTypes = {
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedYear: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

YearSelector.defaultProps = {
  selectedYear: null,
};

export default YearSelector;
