// src/admin_elements/calculations/Rates/sections/RatesMidLevel.jsx

import React, { useState, useEffect } from 'react';
// Adjust the import path to wherever fetchRatesMidLevel.js is located:

import { fetchRatesMidLevel } from '../../../../services/api/fetchRatesMidLevel';
import styles from '../RatesCalculations.module.css';

const RatesMidLevel = ({ dataAgreementId, quarterDate }) => {
  const [midData, setMidData] = useState([]);
  const [midError, setMidError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadMidLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadMidLevelData = async () => {
    setLoading(true);
    setMidError(null);
    setMidData([]);

    const { data, error } = await fetchRatesMidLevel(dataAgreementId, quarterDate);

    if (error) {
      setMidError(error);
      setLoading(false);
      return;
    }

    setMidData(data);
    setLoading(false);
  };

  return (
    <div>
      <h3>Mid-Level Calculations</h3>

      {loading && <p>Loading mid-level rates...</p>}

      {midError && (
        <p className={styles.error}>
          {midError.message || 'Error fetching mid-level rates data.'}
        </p>
      )}

      {midData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Rates Name</th>
              <th>Type Name</th>
              <th>Amount</th>
              <th>Level 1 Rate</th>
              <th>Level 2 Rate</th>
              <th>Level 3 Rate</th>
            </tr>
          </thead>
          <tbody>
            {midData.map((row, index) => (
              <tr key={`${row.quarterdate}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.rates_base_all_purpose}</td>
                <td>{row.typename}</td>
                <td>{row.amount}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading &&
        !midError && <p>No mid-level data found for the selected criteria.</p>
      )}
    </div>
  );
};

export default RatesMidLevel;
