// src/admin_elements/calculations/Allowances/AllowancesCalculations.jsx

import React, { useState } from 'react';
import styles from './AllowancesCalculations.module.css'; // Ensure CSS is correctly referenced

// Sub-sections
import AllowancesTopLevel from './sections/AllowancesTopLevel';
import AllowancesMidLevel from './sections/AllowancesMidLevel';
import AllowancesGranular from './sections/AllowancesGranular';

const AllowancesCalculations = ({ dataAgreementId, quarterDate, tradepersonId }) => {
  // Accordion toggles
  const [topOpen, setTopOpen] = useState(true);
  const [midOpen, setMidOpen] = useState(false);
  const [granOpen, setGranOpen] = useState(false);

  return (
    <div className={styles.accordionContainer}>
      <h2>Allowances Calculations</h2>

      {/* TOP-LEVEL */}
      <div className={styles.accordionItem}>
        <div className={styles.accordionHeader} onClick={() => setTopOpen(!topOpen)}>
          <span>Top-Level Summary</span>
          <span className={styles.toggleIcon}>{topOpen ? '-' : '+'}</span>
        </div>
        {topOpen && (
          <div className={styles.accordionContent}>
            <AllowancesTopLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate} // Pass quarterDate prop
            />
          </div>
        )}
      </div>

      {/* MID-LEVEL */}
      <div className={styles.accordionItem}>
        <div className={styles.accordionHeader} onClick={() => setMidOpen(!midOpen)}>
          <span>Mid-Level Details</span>
          <span className={styles.toggleIcon}>{midOpen ? '-' : '+'}</span>
        </div>
        {midOpen && (
          <div className={styles.accordionContent}>
            <AllowancesMidLevel
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate}
            />
          </div>
        )}
      </div>

      {/* GRANULAR */}
      <div className={styles.accordionItem}>
        <div className={styles.accordionHeader} onClick={() => setGranOpen(!granOpen)}>
          <span>Granular Data</span>
          <span className={styles.toggleIcon}>{granOpen ? '-' : '+'}</span>
        </div>
        {granOpen && (
          <div className={styles.accordionContent}>
            <AllowancesGranular
              dataAgreementId={dataAgreementId}
              quarterDate={quarterDate} // Pass quarterDate if needed
              tradepersonId={tradepersonId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllowancesCalculations;
