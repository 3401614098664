// src/admin_elements/Calculations/Superable/SuperableGraph.jsx

import React from 'react';
import CalculationGraph from '../CalculationGraph'; // Adjust the import path as necessary

const SuperableGraph = () => {
  return <CalculationGraph calcType="Superable" />;
};

export default SuperableGraph;
