// src/admin_elements/utils/dateUtils.js

import * as XLSX from 'xlsx';
import { parse, format } from 'date-fns';

/**
 * Converts Excel serial number to JavaScript Date object.
 * @param {number} serial - Excel serial date number.
 * @returns {Date|null} - JavaScript Date object or null if invalid.
 */
export const excelSerialToDate = (serial) => {
  const date = XLSX.SSF.parse_date_code(serial);
  if (date) {
    return new Date(date.y, date.m - 1, date.d);
  }
  return null;
};

/**
 * Parses a date string into 'YYYY-MM-DD' format.
 * @param {string} dateString - The date string to parse.
 * @param {string} formatString - The format of the input date string.
 * @returns {string|null} - Formatted date string or null if parsing fails.
 */
export const parseDateString = (dateString, formatString = 'dd/MM/yyyy') => { // Changed default format
  try {
    const parsedDate = parse(dateString, formatString, new Date());
    return format(parsedDate, 'yyyy-MM-dd');
  } catch (error) {
    console.error(`Error parsing date string "${dateString}" with format "${formatString}":`, error);
    return null;
  }
};

/**
 * Formats a JavaScript Date object into 'YYYY-MM-DD' format.
 * @param {Date} date - The Date object to format.
 * @returns {string|null} - Formatted date string or null if formatting fails.
 */
export const formatDate = (date) => {
  try {
    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    console.error(`Error formatting date "${date}":`, error);
    return null;
  }
};
