// src/admin_elements/calculations/Rates/sections/RatesTopLevel.jsx
import React, { useState, useEffect } from 'react';
import { fetchRatesAllPurpose } from '../../../../services/api/fetchRatesAllPurpose';
import styles from '../RatesCalculations.module.css';

const RatesTopLevel = ({ dataAgreementId, quarterDate }) => {
  const [topData, setTopData] = useState([]);
  const [topError, setTopError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadTopLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadTopLevelData = async () => {
    setLoading(true);
    setTopError(null);
    setTopData([]);

    const { data, error } = await fetchRatesAllPurpose(dataAgreementId, quarterDate);
    if (error) {
      setTopError(error);
      setLoading(false);
      return;
    }

    setTopData(data);
    setLoading(false);
  };

  if (!dataAgreementId || !quarterDate) {
    return <p>Please select a Data Agreement and Quarter Date.</p>;
  }

  return (
    <div>
      {loading && <p>Loading top-level rates...</p>}
      {topError && (
        <p className={styles.error}>
          {topError.message || 'Error fetching top-level rates data'}
        </p>
      )}

      {topData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Level 1 Rate</th>
              <th>Level 2 Rate</th>
              <th>Level 3 Rate</th>
            </tr>
          </thead>
          <tbody>
            {topData.map((row) => (
              <tr key={`${row.dataagreementid}-${row.quarterdate}`}>
                <td>{row.quarterdate}</td>
                <td>{row.level1_allpurposerate}</td>
                <td>{row.level2_allpurposerate}</td>
                <td>{row.level3_allpurposerate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading &&
        !topError && <p>No top-level rates found for current selection.</p>
      )}
    </div>
  );
};

export default RatesTopLevel;
