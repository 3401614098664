// src/admin_elements/calculations/Superable/sections/SuperableGranular.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditableTable from '../../../../components/EditableTable';
import { fetchSuperableGranularTables } from '../../../../services/api/fetchSuperableGranularTables';
import { fetchGranularData } from '../../../../services/api/fetchGranularData';
import { updateTable } from '../../../../services/api/update_table';
import { TABLE_COLUMNS } from '../../../../services/tableColumns'; // make sure path is correct
import useUserEmail from '../../../../hooks/useUserEmail';
import { toast } from 'react-toastify';
import styles from '../SuperableCalculations.module.css';

const SuperableGranular = ({ dataAgreementId, tradepersonId }) => {
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [data, setData] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const userEmail = useUserEmail();

  // 1. Fetch table names on mount
  useEffect(() => {
    const loadTables = async () => {
      setLoadingTables(true);
      setError(null);
      try {
        // e.g. fetchSuperableGranularTables() -> [{ table_name: "dim_data_superable1" }, ...]
        const tables = await fetchSuperableGranularTables();
        setTableNames(tables.map((t) => t.table_name) || []);
      } catch (err) {
        setError(err.message || 'Failed to load table names.');
        toast.error(err.message || 'Failed to load table names.');
      } finally {
        setLoadingTables(false);
      }
    };
    loadTables();
  }, []);

  // 2. Fetch data whenever selectedTable changes
  useEffect(() => {
    if (!selectedTable) {
      setData([]);
      return;
    }
    const loadData = async () => {
      setLoadingData(true);
      setError(null);
      try {
        const fetchedData = await fetchGranularData(selectedTable, dataAgreementId, tradepersonId);
        setData(fetchedData);
      } catch (err) {
        setError(err.message || 'Failed to load table data.');
        toast.error(err.message || 'Failed to load table data.');
      } finally {
        setLoadingData(false);
      }
    };
    loadData();
  }, [selectedTable, dataAgreementId, tradepersonId]);

  // 3. Toggle table selection
  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName === selectedTable ? null : tableName);
  };

  // 4. Handle row updates
  const handleUpdate = async (rowIndex, columnKey, newValue) => {
    // Instead of TABLE_COLUMNS.superable, we look up the config for selectedTable
    const tableConfig = TABLE_COLUMNS[selectedTable];
    if (!tableConfig) {
      const errMsg = `No configuration found for table "${selectedTable}".`;
      setError(errMsg);
      toast.error(errMsg);
      return;
    }

    const primaryKey = tableConfig.primaryKey;
    const primaryKeyValue = data[rowIndex][primaryKey];

    try {
      const response = await updateTable({
        table_name: selectedTable,
        primary_key_column: primaryKey,
        primary_key_value: primaryKeyValue,
        column_name: columnKey,
        new_value: newValue,
        user_email: userEmail,
      });

      if (response.success) {
        toast.success(response.message || 'Updated successfully!');
        // Optimistic UI update
        const updatedData = [...data];
        updatedData[rowIndex][columnKey] = newValue;
        setData(updatedData);
      } else {
        toast.error(response.message || 'Failed to update.');
      }
    } catch (err) {
      toast.error(err.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div>
      <h2>Superable Granular Data</h2>

      {loadingTables && <p>Loading tables...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {/* Table selection buttons */}
      <div className={styles.tableButtonContainer}>
        {tableNames.map((tbl) => (
          <button
            key={tbl}
            onClick={() => handleTableSelect(tbl)}
            className={`${styles.tableButton} ${selectedTable === tbl ? styles.active : ''}`}
          >
            {tbl}
          </button>
        ))}
      </div>

      {/* Editable table */}
      {selectedTable && (
        <div className={styles.tableContainer}>
          <h3>Editing: {selectedTable}</h3>
          {loadingData ? (
            <p>Loading data...</p>
          ) : data.length === 0 ? (
            <p>No rows found for {selectedTable}.</p>
          ) : (
            (() => {
              const tableConfig = TABLE_COLUMNS[selectedTable];
              if (!tableConfig) {
                return (
                  <p className={styles.error}>
                    No configuration for "{selectedTable}" in TABLE_COLUMNS.
                  </p>
                );
              }

              return (
                <EditableTable
                  columns={tableConfig.columns}
                  data={data}
                  primaryKey={tableConfig.primaryKey}
                  editableColumns={tableConfig.editable}
                  onUpdate={handleUpdate}
                />
              );
            })()
          )}
        </div>
      )}
    </div>
  );
};

SuperableGranular.propTypes = {
  dataAgreementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tradepersonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SuperableGranular.defaultProps = {
  tradepersonId: null,
};

export default SuperableGranular;
