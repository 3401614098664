// src/admin_elements/calculations/Allowances/sections/AllowancesMidLevel.jsx

import React, { useState, useEffect } from 'react';
import { fetchAllowancesMidLevel } from '../../../../services/api/fetchAllowancesMidLevel';
import styles from '../AllowancesCalculations.module.css'; // Ensure CSS is correctly referenced

const AllowancesMidLevel = ({ dataAgreementId, quarterDate }) => {
  const [midData, setMidData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataAgreementId && quarterDate) {
      loadMidLevelData();
    }
  }, [dataAgreementId, quarterDate]);

  const loadMidLevelData = async () => {
    setLoading(true);
    setError(null);
    setMidData([]);

    try {
      const data = await fetchAllowancesMidLevel(dataAgreementId, quarterDate);
      setMidData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>Mid-Level Allowances Details</h3>

      {loading && <p>Loading mid-level allowances...</p>}
      {error && <p className={styles.error}>{error}</p>}

      {midData.length > 0 ? (
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Quarter Date</th>
              <th>Allowances Flat</th>
              <th>Allowances Order</th>
              <th>Type Name</th>
              <th>No. of Units per Annum</th>
              <th>Amount</th>
              <th>Level 1 Class</th>
              <th>Level 2 Class</th>
              <th>Level 3 Class</th>
            </tr>
          </thead>
          <tbody>
            {midData.map((row, index) => (
              <tr key={`${row.quarterdate}-${index}`}>
                <td>{row.quarterdate}</td>
                <td>{row.allowances_flat}</td>
                <td>{row.allowances_order}</td>
                <td>{row.typename}</td>
                <td>{row.no_of_units_per_annum}</td>
                <td>{row.amount}</td>
                <td>{row.level1_class}</td>
                <td>{row.level2_class}</td>
                <td>{row.level3_class}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && !error && <p>No mid-level allowances data found.</p>
      )}
    </div>
  );
};

export default AllowancesMidLevel;
